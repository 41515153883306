import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, where, getDoc, getDocs, doc, Timestamp, } from "firebase/firestore";
import StudyIcon from '../images/buttons/study_icon_mobile.svg';
import Download from '../images/buttons/download_mobile.svg';

function MessageRead(props) {
    const navigate = useNavigate();
    const { state } = useLocation();    //전 페이지에서 넘긴 매개변수 저장하는 변수
    const messageid = state.id;

    const [isLoading, setIsLoading] = useState(true);
    const [messageData, setMessageData] = useState();
    const [receiverData, setReceiverData] = useState();
    const [refreshTrigger, setRefreshTrigger] = useState(0);

    const callDb = useCallback(async () => {
        setIsLoading(true);
        const docRef = doc(db, "messages", messageid);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            const data = docSnap.data();
            setMessageData({
                id: docSnap.id,  // 문서 ID 추가
                ...data  // 기존 문서 데이터 전개
            });
        } else {
            // docSnap.data() will be undefined in this case
            console.log("No such document!");
        }

        // 수신자 정보
        const receiverQuery = query(collection(db, "receivers"), where("origin", "==", messageid));
        const snapshot = await getDocs(receiverQuery);
        const receiverData = snapshot.docs.map((doc) => {
            return {
                ...doc.data(),
                id: doc.id,
            };
        });  
        setReceiverData(receiverData);
        setIsLoading(false);
    }, [messageid])

    useEffect(() => {
        callDb();
    }, [callDb, refreshTrigger])

    // 날짜 포맷팅 함수
    const formatDate = (timestamp) => {
        if (!(timestamp instanceof Timestamp)) {
            return '';  // 또는 다른 기본값
        }
        
        const date = timestamp.toDate();
        return new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        }).format(date).replace(/\. /g, '-').replace('.', '');
    };

    return (
        <>
            {isLoading ? (
                <div />
            ) : (
                <>
                    <div className="content_wrap" style={{alignItems:'flex-start'}}>
                        <div style={{width:"100%",marginTop:"1.25rem"}}>
                            <div style={{color:"#191919",fontWeight:"500",fontSize:"1.38rem"}}>공지사항</div>
                        </div>
                        <div style={{marginTop:"2.38rem",padding:"0.3rem 0.5rem",background:'#F0F0F0',width:"100%"}}>
                            <div>{messageData.username}</div>
                            <div style={{display:'flex'}}>
                                <div>등록일: {formatDate(messageData.regdate)}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>글번호: {messageData.seq}</div>
                            </div>
                        </div>
                        <div style={{padding:"1rem"}}>
                            <div style={{fontSize:"1.2rem",fontWeight:600,marginBottom:"2rem"}}>{messageData.title}</div>
                            <div style={{whiteSpace:'pre-line'}}>{messageData.content}</div>
                        </div>
                        <div style={{marginTop:"2.38rem"}}>발송대상</div>
                        <div style={{padding:"0.3rem 0.5rem",background:'#F0F0F0', width:"100%"}}>
                        {receiverData.map((option, index) => {
                            return (
                                <div key={index} style={{width:"100%",borderTop:"1px #F0F0F0 solid",padding:"0.3rem 0rem",display:'flex',alignItems:'center',
                                    fontSize:"0.875rem",fontWeight:500,
                                }}>
                                    <div style={{marginRight:"1rem"}}>좌석번호: {option.seatno}</div>
                                    <div style={{marginRight:"1rem"}}>{option.destination}</div>
                                    <div style={{marginRight:"1rem"}}>{option.membertype === "P" ? "학부모" : "학생"}</div>
                                    <div style={{marginRight:"1rem"}}>{option.readyn === "N" ? "읽지않음" : "읽음"}</div>
                                    <div style={{marginRight:"1rem"}}>{option.readyn === "Y" && formatDate(option.readdate)}</div>
                                </div>
                            )
                        })}
                        </div>
                        <div style={{width:"100%",borderTop:"1px #F0F0F0 solid"}} />
                        <div style={{width:"100%",display:'flex',justifyContent:'center',margin:"3rem 0rem"}}>
                            <button onClick={()=>navigate(-1)}
                                style={{padding:"0.5rem 1rem",background:"#000",color:"#FFF",cursor:'pointer',marginRight:"1rem"}}
                            >뒤로</button>
                        </div>
                    </div>
                    {/* 태블릿 영역 */}
                    <div className="content_wrap_tablet">
                        <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"20px"}}>
                            <div style={{width:"40%",color:"#191919",fontWeight:"500",fontSize:"20px"}}>공지사항</div>
                        </div>
                        <div style={{marginTop:"2.38rem",padding:"0.3rem 0.5rem",background:'#F0F0F0',width:"95%"}}>
                            <div>{messageData.username}</div>
                            <div style={{display:'flex'}}>
                                <div>등록일: {formatDate(messageData.regdate)}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>글번호: {messageData.seq}</div>
                            </div>
                        </div>
                        <div style={{padding:"1rem",width:'95%'}}>
                            <div style={{fontSize:"1.2rem",fontWeight:600,marginBottom:"2rem"}}>{messageData.title}</div>
                            <div>{messageData.content}</div>
                        </div>
                        <div style={{marginTop:"2.38rem",width:'95%'}}>발송대상</div>
                        <div style={{padding:"0.3rem 0.5rem",background:'#F0F0F0',width:"95%"}}>
                        {receiverData.map((option, index) => {
                            return (
                                <div key={index} style={{width:"100%",borderTop:"1px #F0F0F0 solid",padding:"0.3rem 0rem",display:'flex',alignItems:'center',
                                    fontSize:"0.875rem",fontWeight:500,
                                }}>
                                    <div style={{marginRight:"1rem"}}>좌석번호: {option.seatno}</div>
                                    <div style={{marginRight:"1rem"}}>{option.destination}</div>
                                    <div style={{marginRight:"1rem"}}>{option.membertype === "P" ? "학부모" : "학생"}</div>
                                    <div style={{marginRight:"1rem"}}>{option.readyn === "N" ? "읽지않음" : "읽음"}</div>
                                    <div style={{marginRight:"1rem"}}>{option.readyn === "Y" && formatDate(option.readdate)}</div>
                                </div>
                            )
                        })}
                        </div>
                        <div style={{width:"95%",borderTop:"1px #F0F0F0 solid"}} />
                        <div style={{width:"95%",display:'flex',justifyContent:'center',margin:"3rem 0rem"}}>
                            <button onClick={()=>navigate(-1)}
                                style={{padding:"0.5rem 1rem",background:"#000",color:"#FFF",cursor:'pointer',marginRight:"1rem"}}
                            >뒤로</button>
                        </div>
                        <footer>
                            <div className="copyright" style={{marginTop:"100px",marginBottom:"50px"}}>
                                <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                            </div>
                        </footer>
                    </div>
                    {/* 모바일 영역 */}
                    <div className="content_wrap_mobile padding_horizonal">
                        <div className="sub_title">
                            <div style={{display:'flex',alignItems:'center'}}>
                                <img src={StudyIcon} alt='공지사항' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                                <div>공지사항</div>
                            </div>
                        </div>
                        <div style={{marginTop:"6rem"}}>
                            <div className="notice_subtitle">{messageData.title}</div>
                            <div className="notice_read_info">
                                <div>{messageData.username}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>{formatDate(messageData.regdate)}</div>
                                <div style={{margin:"0rem 0.3rem"}}>|</div>
                                <div>글번호 {messageData.seq}</div>
                            </div>
                        </div>
                        <div className="notice_content notice_read">
                            <div>{messageData.content}</div>
                        </div>
                        <div className="notice_read_subtitle">발송대상</div>
                        {receiverData.map((option, index) => {
                            return (
                                <div key={index} className="notice_filelist">
                                    <div>좌석번호: {option.seatno}</div>
                                    <div>{option.destination}</div>
                                    <div>{option.membertype === "P" ? "학부모" : "학생"}</div>
                                    <div>{option.readyn === "N" ? "읽지않음" : "읽음"}</div>
                                    <div>{option.readyn === "Y" && formatDate(option.readdate)}</div>
                                </div>
                            )
                        })}
                        <div style={{display:'flex',justifyContent:'center',margin:"5rem 0rem"}}>
                            <button className="notice_save back" onClick={()=>navigate(-1)}>뒤로</button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default MessageRead;