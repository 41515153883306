import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDoc, getDocs, updateDoc, doc, setDoc, deleteDoc, where, orderBy } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import Swal from "sweetalert2";
import BtnPlus from '../images/buttons/plus.png';
import BtnDownload from '../images/buttons/download.png';
import BtnUpload from '../images/buttons/upload.png';
import BtnSearch from '../images/buttons/search.png';
import BtnBlue from '../images/buttons/circle_blue.png';
import BtnEmpty from '../images/buttons/circle_empty.png';
import BtnRed from '../images/buttons/circle_red.png';
import BtnArrowDown from '../images/buttons/arrow_down_grey_16.png'
import * as XLSX from 'xlsx';
import UserIcon from '../images/buttons/user_icon_mobile.svg';
import Downward from '../images/buttons/downward_mobile.svg';
import ArrowUpDown from '../images/buttons/arrow_updown_mobile.svg';
import SearchWhite from '../images/buttons/search_white_mobile.svg';
import Etc from '../images/buttons/etc.svg';
import EtcMod from '../images/buttons/etc_mod_mobile.svg';
import EtcDel from '../images/buttons/etc_del_mobile.svg';

function StudentMng() {
    const navigate = useNavigate();
    const searhNameRef = useRef(null);
    const searhNameRefTablet = useRef(null);
    const searhNameRefMobile = useRef(null);
    const fileInput = useRef();

    const [studentData, setStudentData] = useState([]);
    const [studentDataRaw, setStudentDataRaw] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState([]);
    const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
    const [excelData, setExcelData] = useState(null);
    const [upFileName, setUpFileName] = useState('');   //원본파일이름
    const [sortCriteria, setSortCriteria] = useState('seatno'); // 초기 정렬 기준은 좌석번호

    const [allSeasons, setAllSeasons] = useState([]);
    const [seasonInfo, setSeasonInfo] = useState([]);   //시즌 정보
    const [groupInfo, setGroupInfo] = useState([]);   //시즌 정보
    const [selSeason, setSelSeason] = useState("");  //선택 시즌 변수
    const [nowSeason, setNowSeason] = useState("");  //현재 시즌 변수
    const [selGroup, setSelGroup] = useState("");  //선택 그룹 변수

    const [showMenu, setShowMenu] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, right: 0 });
    const menuRef = useRef(null);

    //강제 리렌더링 처리 state
    const [updateState, setUpdateState] = useState(false);

    async function fetchAllSeasonInfo() {
        const groupQ = query(collection(db, "group"), orderBy("seq"));
        const groupSnapshot = await getDocs(groupQ);
    
        const groupData = groupSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        setGroupInfo(groupData);
        setSelGroup(groupData[0].groupid);
    
        const seasonQ = query(collection(db, "seasoninfo"), orderBy("seq", 'desc'));
        const seasonSnapshot = await getDocs(seasonQ);
    
        const seasonData = seasonSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
    
        return seasonData; // 모든 시즌 데이터 반환
    }
    
    function filterSeasonsByGroup(allSeasons, groupId) {
        const filteredSeasons = allSeasons.filter(season => season.groupid === groupId);
        setSeasonInfo(filteredSeasons);
    
        // nowyn 필드가 true인 항목의 seasoncode를 찾아 setSelSeason에 설정
        const currentSeason = filteredSeasons.find(season => season.nowyn === true);
        if (currentSeason) {
            setSelSeason(currentSeason.seasoncode);
            setNowSeason(currentSeason.seasoncode);
        }
    }

    //시즌 정보 코드
    useEffect(() => {
        async function fetchData() {
            const seasons = await fetchAllSeasonInfo();
            setAllSeasons(seasons);
        }
        fetchData();
    }, []);
    
    useEffect(() => {
        if (selGroup && allSeasons.length > 0) {
            filterSeasonsByGroup(allSeasons, selGroup);
        }
    }, [selGroup, allSeasons]);

    const callDb = useCallback(async () => {
        const q = query(collection(db, "users"),where("auth","==",1),where("groupid", "==", selSeason),
                where("membertype", "==", "C"));
        const snapshot = await getDocs(q);
        const histq = query(collection(db, "userhistory"),where("groupid", "==", selSeason),
                    where("membertype", "==", "C"));
        const histsnapshot = await getDocs(histq); 

        const combinedData = snapshot.docs.concat(histsnapshot.docs).map(doc => {
            const seatno = doc.data().seatno;
            const numericSeatno = seatno !== "" ? parseInt(seatno, 10) : seatno;

            return {
                ...doc.data(),
                id: doc.id,
                seatno: numericSeatno,
            };
        });

        setStudentData(combinedData);
        setStudentDataRaw(combinedData);

        const initialCheckboxStates = combinedData.map((item) => ({
            id: item.id,
            checked: false,
        }));
        setCheckboxStates(initialCheckboxStates);
        setSelectAllCheckbox(false);
    }, [selSeason])

    useEffect(() => {
        callDb();
    }, [callDb, updateState])

    const handleFileUpload = (e) => {
        const file = e.target.files[0];

        if (file) {
            const reader = new FileReader();
        
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, { type: 'array' });
                const sheetName = workbook.SheetNames[0];
                const worksheet = workbook.Sheets[sheetName];
                const dataJSON = XLSX.utils.sheet_to_json(worksheet);
        
                setExcelData(dataJSON);
            };
    
            reader.readAsArrayBuffer(file);
            setUpFileName(file.name);
        }
    }

    const stdBulkUpload = () => {
        if(selSeason === nowSeason){
            if(excelData !== null){
                let successCnt = 0;
                let failCnt = 0;
    
                //map처리 새로운 방식
                const processDocuments = async () => {
                    for (const document of excelData) {
                        const onlyNumber = document.휴대전화번호.replace(/[^0-9]/g, '')
    
                        if(document.이름 === "김예시" && document.학교 === "예시고등학교"){
                            ++failCnt;
                        }else{
                            if(document.휴대전화번호 !== "" && document.이름 !== ""){
                                if(onlyNumber.length === 11){
                                    const docRef = doc(db, "users", onlyNumber)
                                    const docSnap = await getDoc(docRef);
    
                                    if(docSnap.exists()){
                                        await updateDoc(docRef, {
                                            confirmyn: "Y",
                                        });
                                    }else{
                                        let gender = "";
                                        let grade = "";
                                        let genderError = "N";
                                        let gradeError = "N";
                                        let parentphone = "";
                                        let seatno = "";
    
                                        if(document.성별 === "남자" || document.성별 === "남"){
                                            gender = "M";
                                        }else if(document.성별 === "여자" || document.성별 === "여"){
                                            gender = "F";
                                        }else if(document.성별 === ""){
                                            gender = "";
                                        }else{
                                            genderError = "Y";
                                        }
    
                                        if(document.학년 === 1 || document.학년 === 2 || document.학년 === 3
                                            || document.학년 === ""){
                                            grade = document.학년;
                                        }else{
                                            gradeError = "Y"
                                        }
    
                                        if(document.부모전화번호 !== ""){
                                            parentphone = document.부모전화번호.replace(/[^0-9]/g, '')
                                        }else{
                                            parentphone = ""
                                        }
    
                                        if(document.좌석번호 !== ""){
                                            seatno = document.좌석번호.replace(/[^0-9]/g, '')
                                        }else{
                                            seatno = ""
                                        }
    
                                        if(genderError === "N" && gradeError === "N"){
                                            try{     
                                                await setDoc(docRef,{
                                                    auth: 1,
                                                    token: "",
                                                    userid: "",
                                                    username: document.이름,
                                                    userphone: onlyNumber,
                                                    parentphone: parentphone,
                                                    school: document.학교,
                                                    grade: grade,
                                                    gender: gender,
                                                    confirmyn: "Y",
                                                    stdcardno: "",
                                                    class: "",
                                                    appinstallyn: "N",
                                                    pointplus: 0,
                                                    pointminus: 0,
                                                    seatno: seatno,
                                                    groupid: "sd",
                                                });
                                                ++successCnt;
                                                //window.location.reload(false);  //페이지 refresh
                                            } catch(error) {
                                                console.log("에러메세지:", error.message);
                                            }
                                        }else{
                                            ++failCnt;
                                        }
                                    }
                                }else{
                                    ++failCnt;
                                }
                            }else{
                                ++failCnt;
                            }   
                        }
                    }
                }
    
                // map이 끝난 후에 알림을 표시
                processDocuments().then(() => {
                    Swal.fire({
                        icon: "success",
                        title: "일괄처리 완료",
                        text: "성공: "+successCnt+"건 실패: "+failCnt+"건",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            setExcelData(null);
                            fileInput.current.value = "";
                            // document.getElementById('fileInput').value = '';
                            setUpFileName("");
                            setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                        }
                    });
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    const downloadTemplate = () => {
        const storage = getStorage();
        // const templateRef = ref(storage, 'documents/231108_test.xlsx');
        const templateRef = ref(storage, 'documents/student_upload_template.xlsx');

        // Get the download URL
        getDownloadURL(templateRef)
        .then((url) => {
            // 만들어진 URL을 사용하여 파일을 다운로드합니다.
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'student_upload_template.xlsx'); // 파일 이름 설정
            link.style.display = 'none'; // 화면에 보이지 않도록 설정
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            console.log(error);
        });
    }

    const newModStudent = (id, appinstallyn) => {
        if(selSeason === nowSeason){
            navigate("/newmodstudent", {
                state: {
                    colId: id,
                    appInstall: appinstallyn
                },
            })
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    const delStudent = async (id, appinstallyn) => {
        if(selSeason === nowSeason){
            if(appinstallyn === "Y"){
                Swal.fire({
                    icon: "error",
                    title: "삭제 불가",
                    text: "앱을 설치한 사용자입니다.",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                    }
                });
            }else if(appinstallyn === "N"){
                Swal.fire({
                    icon: "question",
                    title: "수강생 삭제",
                    text: "삭제 하시겠습니까?",
                    showCancelButton: true,
                    confirmButtonText: "삭제",
                    cancelButtonText: "취소",
                }).then(async (res) => {
                    if (res.isConfirmed) {
                        try{
                            await deleteDoc(doc(db, "users", id))
                
                            Swal.fire({
                                icon: "success",
                                title: "삭제 완료",
                                text: "학생 삭제가 완료되었습니다",
                                //showCancelButton: true,
                                confirmButtonText: "확인",
                                //cancelButtonText: "취소",
                            }).then((res) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (res.isConfirmed) {
                                    //확인 요청 처리
                                    setShowMenu(null);
                                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                }
                            });
                        }catch(error){
                            console.log(error.message);
                        }
                    }
                })
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    const seasonoutStudent = async (id, groupid) => {
        if(selSeason === nowSeason || selSeason === "noseason"){
            if(groupid !== "noseason"){
                Swal.fire({
                    icon: "question",
                    title: "시즌제외처리",
                    text: "시즌제외처리 하시겠습니까?",
                    showCancelButton: true,
                    confirmButtonText: "확인",
                    cancelButtonText: "취소",
                }).then(async (res) => {
                    if (res.isConfirmed) {
                        try{
                            await updateDoc(doc(db, "users", id), {
                                groupid: "noseason",
                            });
                
                            Swal.fire({
                                icon: "success",
                                title: "시즌제외처리 완료",
                                text: "시즌제외처리가 완료되었습니다",
                                //showCancelButton: true,
                                confirmButtonText: "확인",
                                //cancelButtonText: "취소",
                            }).then((res) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (res.isConfirmed) {
                                    //확인 요청 처리
                                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                }
                            });
                        }catch(error){
                            console.log(error.message);
                        }
                    }
                })
            }else {
                Swal.fire({
                    icon: "question",
                    title: "시즌처리",
                    text: "시즌처리 하시겠습니까?",
                    showCancelButton: true,
                    confirmButtonText: "확인",
                    cancelButtonText: "취소",
                }).then(async (res) => {
                    if (res.isConfirmed) {
                        try{
                            // `seasonInfo`에서 가장 큰 `seq` 값을 가진 객체를 찾기
                            const maxSeqSeason = seasonInfo.reduce((prev, current) => {
                                return (prev.seq > current.seq) ? prev : current;
                            }, {});

                            // 가장 큰 `seq` 값을 가진 객체의 `groupid`를 가져오기
                            const newGroupId = maxSeqSeason.seasoncode || "";
                            
                            await updateDoc(doc(db, "users", id), {
                                groupid: newGroupId,
                            });
                
                            Swal.fire({
                                icon: "success",
                                title: "시즌처리 완료",
                                text: "시즌처리 완료되었습니다",
                                //showCancelButton: true,
                                confirmButtonText: "확인",
                                //cancelButtonText: "취소",
                            }).then((res) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (res.isConfirmed) {
                                    //확인 요청 처리
                                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                }
                            });
                        }catch(error){
                            console.log(error.message);
                        }
                    }
                })
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    //이름조회 input 처리로직
    const handleSearch = (props) => {
        // const nameValue = searhNameRef.current.value;
        const nameValue = props
        setStudentData(studentDataRaw);
        
        if(nameValue !== ""){
            const filteredCustomer = studentDataRaw.filter(item => item.username.includes(nameValue));
            setStudentData(filteredCustomer);
        }else{
            callDb();
        }
    }

    //체크박스 선택로직
    const handleCheckboxChange = (id) => {
        const updatedCheckboxStates = checkboxStates.map((item) =>
            item.id === id ? { ...item, checked: !item.checked } : item
        );
        setCheckboxStates(updatedCheckboxStates);
        setStudentData((prevRows) =>
            prevRows.map((row) =>
                row.id === id ? { ...row, selected: !row.selected } : row
            )
        );
    }

    const handleSelectAllCheckboxChange = () => {
        setSelectAllCheckbox(!selectAllCheckbox);
        const updatedCheckboxStates = checkboxStates.map((item) => ({
            ...item,
            checked: !selectAllCheckbox,
        }));
        setCheckboxStates(updatedCheckboxStates);
        setStudentData((prevRows) =>
            prevRows.map((row) => ({ ...row, selected: !selectAllCheckbox }))
        );
    };
    
    //체크박스 선택한 항목 승인로직
    const handleConfirm = (props) => {
        if(selSeason === nowSeason){
            const selectedIds = studentData.filter(row => row.selected === true);
            let confirmYn;

            if(props === "Y"){
                confirmYn = "승인";
            }else{
                confirmYn = "해제";
            }
    
            if(selectedIds.length === 0){
                Swal.fire({
                    icon: "error",
                    // title: "수강생 삭제",
                    text: "선택된 수강생이 없습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                })
            }else{
                Swal.fire({
                    icon: "question",
                    title: `선택 ${confirmYn}`,
                    text: `선택한 수강생 ${confirmYn}처리 하시겠습니까?`,
                    showCancelButton: true,
                    confirmButtonText: `${confirmYn}`,
                    cancelButtonText: "취소",
                }).then((res) => {
                    if (res.isConfirmed) {
                        try{
                            selectedIds.map(async (row) => {
                                await updateDoc(doc(db, "users", row.id), {
                                    confirmyn: props,
                                });
                            })
                            
                            Swal.fire({
                                icon: "success",
                                title: `${confirmYn}처리 완료`,
                                // text: "",
                                //showCancelButton: true,
                                confirmButtonText: "확인",
                                //cancelButtonText: "취소",
                            }).then((res) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (res.isConfirmed) {
                                    //확인 요청 처리
                                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                }
                            });
                        } catch(error){
                            console.log(error.message);
                        }
                    }
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    };

    const handleEtcClick = (event, docId) => {
        event.preventDefault();
        const rect = event.target.getBoundingClientRect();
        setMenuPosition({
            top: rect.bottom + window.scrollY,
            right: window.innerWidth - rect.right + window.scrollX
        });
        setShowMenu(docId);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(null);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [menuRef]);

    const delSelectedRow = () => {
        if(selSeason === nowSeason){
            const selectedIds = studentData.filter(row => row.selected === true);

            if(selectedIds.length === 0){
                Swal.fire({
                    icon: "error",
                    title: "수강생 삭제",
                    text: "선택된 수강생이 없습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                })
            }else{
                Swal.fire({
                    icon: "question",
                    title: "수강생 삭제",
                    html: "앱 설치된 수강생은 삭제 불가능합니다<br>선택된 수강생을 삭제하겠습니까?",
                    showCancelButton: true,
                    confirmButtonText: "삭제",
                    cancelButtonText: "취소",
                }).then((res) => {
                    if (res.isConfirmed) {
                        try{
                            selectedIds.map(async (row) => {
                                if(row.appinstallyn === "N"){
                                    await deleteDoc(doc(db, "users", row.id))
                                }
                            })
                            Swal.fire({
                                icon: "success",
                                title: "수강생 삭제",
                                text: "수강생 삭제가 완료되었습니다",
                                //showCancelButton: true,
                                confirmButtonText: "확인",
                                //cancelButtonText: "취소",
                            }).then((res) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (res.isConfirmed) {
                                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                }
                            });
                        }
                        catch(error){
                            console.log(error);
                        }
                    }
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    const seasonOutSelectedRow = () => {
        if(selSeason === nowSeason){
            const selectedIds = studentData.filter(row => row.selected === true);

            if(selectedIds.length === 0){
                Swal.fire({
                    icon: "error",
                    title: "시즌제외처리",
                    text: "선택된 수강생이 없습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                })
            }else{
                Swal.fire({
                    icon: "question",
                    title: "시즌제외처리",
                    html: "선택된 수강생을 시즌제외 처리하겠습니까?",
                    showCancelButton: true,
                    confirmButtonText: "확인",
                    cancelButtonText: "취소",
                }).then((res) => {
                    if (res.isConfirmed) {
                        try{
                            selectedIds.map(async (row) => {
                                await updateDoc(doc(db, "users", row.id), {
                                    groupid: "noseason",
                                });
                            })
                            Swal.fire({
                                icon: "success",
                                title: "시즌제외처리",
                                text: "시즌제외처리가 완료되었습니다",
                                //showCancelButton: true,
                                confirmButtonText: "확인",
                                //cancelButtonText: "취소",
                            }).then((res) => {
                                /* Read more about isConfirmed, isDenied below */
                                if (res.isConfirmed) {
                                    setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                                }
                            });
                        }
                        catch(error){
                            console.log(error);
                        }
                    }
                });
            }
        }else{
            Swal.fire({
                icon: "error",
                title: "편집불가",
                text: "지난 시즌은 편집할 수 없습니다",
                //showCancelButton: true,
                confirmButtonText: "확인",
                //cancelButtonText: "취소",
            })
        }
    }

    // 파일태그 클릭효과
    const onClickContentUpload = () => {
        fileInput.current.click();
    }

    function formatPhoneNumber(phoneNumber) {
        // 정규식을 사용하여 숫자만 추출
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
        // 전화번호 형식으로 변환
        const formatted = cleaned.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    
        return formatted;
    }

    
    const handleSortClick = (criteria) => {
        setSortCriteria(criteria);
    };

    return (
        <>
            <div className="content_wrap">
                <div style={{width:"100%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{width:"40%",color:"#191919",fontWeight:"500",fontSize:"1.375rem"}}>수강생관리</div>
                    <div style={{display:'flex'}}>
                        <div className="btn_upside" style={{width:"6.375rem",height:"2.5rem"}}>
                            <img src={BtnPlus} className="btn_icon" style={{marginLeft:"0.625rem"}} alt='신규'></img>
                            <div className="btn_text" style={{color:"#0F5EFE"}}
                                onClick={()=>newModStudent("new", "N")}
                            >신규생성</div>
                        </div>
                        <div className="btn_upside" style={{width:"9.25rem",height:"2.5rem",marginLeft:"0.9375rem"}}>
                            <img src={BtnDownload} className="btn_icon" style={{marginLeft:"0.9375rem"}} alt='다운로드'></img>
                            <div className="btn_text" style={{color:"#191919"}}
                                onClick={downloadTemplate}
                            >템플릿 다운로드</div>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',width:"21.25rem",height:"2.5rem",
                                    borderRadius:"3.125rem",backgroundColor:"#F4F4F4",marginLeft:"0.9375rem"}}>
                            <div className="btn_upside" style={{width:"5.938rem",height:"2.5rem"}}>
                                <img src={BtnUpload} className="btn_icon" style={{marginLeft:"0.8125rem"}} alt='업로드'></img>
                                <div className="btn_text" style={{color:"#191919"}}
                                    onClick={()=>stdBulkUpload()}
                                >업로드</div>
                            </div>
                            <input 
                                type="file"
                                ref={fileInput}
                                style={{display:"none"}}
                                onChange={handleFileUpload}
                            ></input>
                            <input
                                style={{color:"#A6A7AB",backgroundColor:"#F4F4F4"}}
                                placeholder="첨부파일" 
                                defaultValue={upFileName}
                                readOnly
                            >
                            </input>
                            <div className="btn_upside" style={{width:"5.313rem",height:"2.5rem",justifyContent:'center'}}>
                                <div style={{color:"#949494",fontWeight:400,fontSize:"0.8125rem"}}
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        onClickContentUpload();
                                    }}
                                >파일선택</div>
                            </div>
                        </div>
                        <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                            <input name="search_input" type="text" placeholder="이름" ref={searhNameRef}
                                style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                            />
                            <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                                onClick={() => {handleSearch(searhNameRef.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{width:"100%",marginTop:"4.375rem",marginBottom:"1.25rem",display:'flex',justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                        <div className="btn_midside" style={{width:"4.25rem"}} onClick={()=>handleConfirm("Y")}>
                            <img src={BtnBlue} className="btn_midicon" alt='승인' />
                            승인
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem"}} onClick={()=>handleConfirm("N")}>
                            <img src={BtnEmpty} className="btn_midicon" alt='승인해제' />
                            승인해제
                        </div>
                        <div className="btn_midside" style={{width:"4.25rem"}} onClick={()=>delSelectedRow()}>
                            <img src={BtnRed} className="btn_midicon" alt='삭제' />
                            삭제
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem"}} onClick={()=>seasonOutSelectedRow()}>
                            <img src={BtnRed} className="btn_midicon" alt='삭제' />
                            시즌제외
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <select className="btn_midside" style={{width:"8.25rem",textAlign:'center'}} 
                                onChange={(e) => {
                                    setSelGroup(e.target.value);
                                }}>
                            {groupInfo.map(group => (
                                <option key={group.groupid} value={group.groupid}>{group.groupname}</option>
                            ))}
                        </select>
                        <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                                onChange={(e) => {
                                    setSelSeason(e.target.value);
                                }}>
                            {seasonInfo.map(season => (
                                <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                            ))}
                        </select>
                        <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                            <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                            좌석번호순
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem",marginRight:"0rem"}} onClick={() => handleSortClick('username')}>
                            <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                            이름순
                        </div>
                    </div>
                </div>
                <div style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <input
                            name="selectAllCheckbox"
                            type="checkbox"
                            style={{width:"0.8125rem",marginRight:"1.25rem"}}
                            checked={selectAllCheckbox}
                            onChange={handleSelectAllCheckboxChange}
                        />
                    </div>
                    <div style={{width:"6.25rem"}}>이름</div>
                    <div style={{width:"8.125rem"}}>좌석번호</div>
                    <div style={{width:"12.5rem"}}>전화번호</div>
                    <div style={{width:"12.5rem"}}>학부모 전화번호</div>
                    <div style={{width:"12.5rem"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"7.5rem"}}>앱</div>
                    {/* <div style={{width:"130px"}}>카드등록</div> */}
                    {/* <div style={{width:"200px"}}>카드번호</div> */}
                    <div style={{width:"9.375rem",textAlign:'center'}}>승인</div>
                    <div style={{width:"15.63rem"}}/>
                </div>
                {studentData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'username') {
                            // 이름을 문자열로 비교
                            return a.username.localeCompare(b.username);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                      })
                    .map((doc) => {
                        let gender;

                        if(doc.gender === "M"){
                            gender = "남";
                        }else if(doc.gender === "F"){
                            gender = "여";
                        }else{
                            gender = "";
                        }
                        
                        const userPhone = formatPhoneNumber(doc.userphone);
                        const parentPhone = formatPhoneNumber(doc.parentphone);
                        const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                        return(
                            <div key={doc.id} style={{width:"100%",height:"2.5rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"2.5rem"}}>
                                <div style={{display:'flex',alignItems:'center'}}>
                                    <input name="body_checkbox" 
                                        type="checkbox"
                                        style={{width:"0.8125rem",marginRight:"1.25rem"}}
                                        checked={checkboxStates.find((c) => c.id === doc.id)?.checked || false}
                                        onChange={() => handleCheckboxChange(doc.id)}
                                    />
                                </div>
                                <div style={{width:"6.25rem",fontWeight:500}}>{doc.username}</div>
                                <div style={{width:"8.125rem"}}>{doc.seatno}</div>
                                <div style={{width:"12.5rem"}}>{userPhone}</div>
                                <div style={{width:"12.5rem"}}>{parentPhone}</div>
                                <div style={{width:"12.5rem"}}>{userInfo}</div>
                                <div style={{width:"7.5rem",color:doc.appinstallyn === "Y" ? "#0F5EFE" : "#D9D9D9"}}>{doc.appinstallyn}</div>
                                {/* <div style={{width:"130px",color:"#8D8D8D",cursor:"pointer"}}>등록</div> */}
                                {/* <div style={{width:"200px"}}>{doc.stdcardno}</div> */}
                                <div style={{width:"9.375rem",textAlign:'center',color:doc.confirmyn === "Y" ? "#0F5EFE" : "#D9D9D9"}}>{doc.confirmyn}</div>
                                <div style={{width:"15.63rem",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                    <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>newModStudent(doc.id, doc.appinstallyn)}>수정</button>
                                    <button className="btn_moddel" style={{color:"#FF594F"}} onClick={()=>delStudent(doc.id, doc.appinstallyn)}>삭제</button>
                                    <button className="btn_moddel" style={{color:"green",width:"5.125rem"}} onClick={()=>seasonoutStudent(doc.id, doc.groupid)}>{doc.groupid === "noseason" ? '시즌처리' : '시즌제외'}</button>
                                </div>
                            </div>
                        )
                    }
                )}
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div style={{width:"40%",color:"#191919",fontWeight:"500",fontSize:"1.25rem"}}>수강생관리</div>
                    <div style={{display:'flex'}}>
                        <div className="btn_upside" style={{width:"2.5rem",height:"2.5rem",marginRight:"0.625rem"}}>
                            <img src={BtnPlus} className="btn_icon" alt='신규'
                                onClick={()=>newModStudent("new", "N")}>
                            </img>
                        </div>
                        {/* <div className="btn_upside" style={{width:"40px",height:"40px"}}>
                            <img src={BtnDownload} className="btn_icon" alt='다운로드'
                                onClick={downloadTemplate}>
                            </img>
                        </div>
                        <div style={{display:'flex',justifyContent:'space-between',width:"248px",height:"40px",
                                    borderRadius:"50px",backgroundColor:"#F4F4F4",marginLeft:"10px"}}>
                            <div className="btn_upside" style={{width:"40px",height:"40px"}}>
                                <img src={BtnUpload} className="btn_icon" alt='업로드'
                                    onClick={stdBulkUpload}>
                                </img>
                            </div>
                            <input 
                                type="file"
                                ref={fileInput}
                                style={{display:"none"}}
                                onChange={handleFileUpload}
                            ></input>
                            <input
                                style={{color:"#A6A7AB",backgroundColor:"#F4F4F4",width:"130px"}}
                                placeholder="첨부파일" 
                                defaultValue={upFileName}
                                readOnly
                            >
                            </input>
                            <div className="btn_upside" style={{width:"66px",height:"40px",justifyContent:'center'}}>
                                <div style={{color:"#949494",fontWeight:400,fontSize:"13px"}}
                                    onClick={(e)=>{
                                        e.preventDefault();
                                        onClickContentUpload();
                                    }}
                                >파일</div>
                            </div>
                        </div> */}
                        <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                            <input name="search_input" type="text" placeholder="이름" ref={searhNameRefTablet}
                                style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                            />
                            <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefTablet.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"4.375rem",marginBottom:"1.25rem",display:'flex',justifyContent:'space-between'}}>
                    <div style={{display:'flex'}}>
                        <div className="btn_midside" style={{width:"4.25rem"}} onClick={()=>handleConfirm("Y")}>
                            <img src={BtnBlue} className="btn_midicon" alt='승인' />
                            승인
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem"}} onClick={()=>handleConfirm("N")}>
                            <img src={BtnEmpty} className="btn_midicon" alt='승인해제' />
                            승인해제
                        </div>
                        <div className="btn_midside" style={{width:"4.25rem"}} onClick={()=>delSelectedRow()}>
                            <img src={BtnRed} className="btn_midicon" alt='삭제' />
                            삭제
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem"}} onClick={()=>seasonOutSelectedRow()}>
                            <img src={BtnRed} className="btn_midicon" alt='삭제' />
                            시즌제외
                        </div>
                    </div>
                    <div style={{display:'flex'}}>
                        <select className="btn_midside" style={{width:"8.25rem",textAlign:'center'}} 
                                onChange={(e) => {
                                    setSelGroup(e.target.value);
                                }}>
                            {groupInfo.map(group => (
                                <option key={group.groupid} value={group.groupid}>{group.groupname}</option>
                            ))}
                        </select>
                        <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                            onChange={(e) => {setSelSeason(e.target.value);}}>
                            {seasonInfo.map(season => (
                                <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                            ))}
                        </select>
                        <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                            <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                            좌석번호순
                        </div>
                        <div className="btn_midside" style={{width:"5.375rem",marginRight:"0rem"}} onClick={() => handleSortClick('username')}>
                            <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                            이름순
                        </div>
                    </div>
                </div>
                <div style={{width:"95%",height:"3rem",display:'flex',justifyContent:'space-between',color:"#A6A7AB",fontWeight:400,fontSize:"1rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <input
                            name="selectAllCheckbox"
                            type="checkbox"
                            style={{width:"0.8125rem",marginRight:"1.25rem"}}
                            checked={selectAllCheckbox}
                            onChange={handleSelectAllCheckboxChange}
                        />
                    </div>
                    {/* <div style={{width:"130px"}}>이름</div>
                    <div style={{width:"190px"}}>전화번호</div>
                    <div style={{width:"190px"}}>학부모 전화번호</div>
                    <div style={{width:"200px"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"140px"}}>카드번호</div>
                    <div style={{width:"40px",textAlign:'center'}}>승인</div>
                    <div style={{width:"130px"}}/> */}
                    <div style={{width:"12%"}}>이름</div>
                    <div style={{width:"19%"}}>전화번호</div>
                    <div style={{width:"19%"}}>학부모 전화번호</div>
                    <div style={{width:"20%"}}>학교 / 학년 / 성별</div>
                    {/* <div style={{width:"15%"}}>카드번호</div> */}
                    <div style={{width:"10%",textAlign:'center'}}>승인</div>
                    <div style={{width:"20%"}}/>
                </div>
                {studentData.map((doc) => {
                    let gender;

                    if(doc.gender === "M"){
                        gender = "남";
                    }else if(doc.gender === "F"){
                        gender = "여";
                    }else{
                        gender = "";
                    }
                    
                    const userPhone = formatPhoneNumber(doc.userphone);
                    const parentPhone = formatPhoneNumber(doc.parentphone);
                    const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                    return(
                        <div key={doc.id} style={{width:"95%",height:"3rem",display:'flex',justifyContent:'space-between',color:"#191919",fontWeight:400,fontSize:"0.875rem",borderBottom:"#E7E7E7 1px solid",lineHeight:"3rem"}}>
                            <div style={{display:'flex',alignItems:'center'}}>
                                <input name="body_checkbox" 
                                    type="checkbox"
                                    style={{width:"0.8125rem",marginRight:"1.25rem"}}
                                    checked={checkboxStates.find((c) => c.id === doc.id)?.checked || false}
                                    onChange={() => handleCheckboxChange(doc.id)}
                                />
                            </div>
                            {/* <div style={{width:"130px",fontWeight:500}}>{doc.username}</div>
                            <div style={{width:"190px"}}>{userPhone}</div>
                            <div style={{width:"190px"}}>{parentPhone}</div>
                            <div style={{width:"200px"}}>{userInfo}</div>
                            <div style={{width:"140px"}}>{doc.stdcardno}</div>
                            <div style={{width:"40px",textAlign:'center',color:doc.confirmyn === "Y" ? "#0F5EFE" : "#D9D9D9"}}>{doc.confirmyn}</div>
                            <div style={{width:"130px",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>newModStudent(doc.id, doc.appinstallyn)}>수정</button>
                                <button className="btn_moddel" style={{color:"#FF594F"}} onClick={()=>delStudent(doc.id, doc.appinstallyn)}>삭제</button>
                            </div> */}
                            <div style={{width:"12%",fontWeight:500}}>{doc.username}</div>
                            <div style={{width:"19%"}}>{userPhone}</div>
                            <div style={{width:"19%"}}>{parentPhone}</div>
                            <div style={{width:"20%"}}>{userInfo}</div>
                            {/* <div style={{width:"15%"}}>{doc.stdcardno}</div> */}
                            <div style={{width:"10%",textAlign:'center',color:doc.confirmyn === "Y" ? "#0F5EFE" : "#D9D9D9"}}>{doc.confirmyn}</div>
                            <div style={{width:"20%",display:'flex',alignItems:'center',justifyContent:'flex-end'}}>
                                <button className="btn_moddel" style={{color:"#8D8D8D"}} onClick={()=>newModStudent(doc.id, doc.appinstallyn)}>수정</button>
                                <button className="btn_moddel" style={{color:"#FF594F"}} onClick={()=>delStudent(doc.id, doc.appinstallyn)}>삭제</button>
                                <button className="btn_moddel" style={{color:"green",width:82}} onClick={()=>seasonoutStudent(doc.id, doc.groupid)}>{doc.groupid === "noseason" ? '시즌처리' : '시즌제외'}</button>
                            </div>
                        </div>
                    )
                })}
                <footer>
                    <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title studentmng">
                    <div style={{display:'flex',alignItems:'center'}}>
                        <img src={UserIcon} alt='수강생관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                        <div>수강생관리</div>
                    </div>
                    <button className="add_student">
                        <img src={BtnPlus} alt='신규'
                            onClick={()=>newModStudent("new", "N")}>
                        </img>
                    </button>
                </div>
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'3.13rem'}}>
                    <div style={{display:'flex'}}>
                        <div className="button_season_sort">
                            <select className="season"  
                                    onChange={(e) => {
                                        setSelGroup(e.target.value);
                                    }}>
                                {groupInfo.map(group => (
                                    <option key={group.groupid} value={group.groupid}>{group.groupname}</option>
                                ))}
                            </select>
                            <img src={Downward} alt='그룹' style={{width:"1rem",height:"1rem"}} />
                        </div>
                        <div style={{width:'0.62rem'}} />
                        <div className="button_season_sort">
                            <select className="season" 
                                onChange={(e) => {setSelSeason(e.target.value);}}>
                                {seasonInfo.map(season => (
                                    <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                                ))}
                            </select>
                            <img src={Downward} alt='시즌' style={{width:"1rem",height:"1rem"}} />
                        </div>
                        <div style={{width:'0.62rem'}} />
                        <div className="button_season_sort" 
                            onClick={() => {
                                if(sortCriteria === "seatno"){
                                    handleSortClick('username')
                                }else{
                                    handleSortClick('seatno')
                                }
                            }}
                        >
                            {sortCriteria === "seatno" ? "좌석순"
                            : "이름순"
                            }
                            <img src={ArrowUpDown} alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        </div>
                    </div>
                    <div className="search_name_area">
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefMobile}/>
                        <div className="button_area">
                            <img src={SearchWhite} style={{width:'0.9375rem',height:'0.99613rem'}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefMobile.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div style={{display:'flex',marginTop:'1.5rem'}}>
                    <button className="manage_area approve" onClick={()=>handleConfirm("Y")}>승인</button>
                    <button className="manage_area approve_cancel" onClick={()=>handleConfirm("N")}>승인해제</button>
                    <button className="manage_area del" onClick={()=>delSelectedRow()}>삭제</button>
                    <button className="manage_area except" onClick={()=>seasonOutSelectedRow()}>시즌제외</button>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="checkbox" />
                        <div className="name">이름</div>
                        <div className="seat">좌석</div>
                        <div className="phone">전화번호</div>
                        <div className="phone">학부모전화번호</div>
                        <div className="personalinfo">학교/학년/성별</div>
                        <div className="approve">승인</div>
                    </div>
                    {studentData
                        .sort((a, b) => {
                            if (sortCriteria === 'seatno') {
                                // 좌석번호 숫자를 비교
                                if (a.seatno !== "" && b.seatno !== "") {
                                    return a.seatno - b.seatno;
                                } else if (a.seatno === "") {
                                    return 1; // null은 뒤로 정렬
                                } else {
                                    return -1; // null은 뒤로 정렬
                                }
                            } else if (sortCriteria === 'username') {
                                // 이름을 문자열로 비교
                                return a.username.localeCompare(b.username);
                            }
                            // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                            return 0;
                        })
                        .map((doc) => {
                            let gender;

                            if(doc.gender === "M"){
                                gender = "남";
                            }else if(doc.gender === "F"){
                                gender = "여";
                            }else{
                                gender = "";
                            }
                            
                            const userPhone = formatPhoneNumber(doc.userphone);
                            const parentPhone = formatPhoneNumber(doc.parentphone);
                            const userInfo = doc.school+" / "+doc.grade+" / "+gender;

                            return(
                                <div className="body_area_mobile" key={doc.id}>
                                    <input name="body_checkbox" 
                                        type="checkbox"
                                        style={{width:"1rem",marginRight:"0.62rem"}}
                                        checked={checkboxStates.find((c) => c.id === doc.id)?.checked || false}
                                        onChange={() => handleCheckboxChange(doc.id)}
                                    />
                                    <div className="name">{doc.username}</div>
                                    <div className="seat">
                                        <div className="seatno">{doc.seatno}</div>
                                    </div>
                                    <div className="phone">{userPhone}</div>
                                    <div className="phone">{parentPhone}</div>
                                    <div className="personalinfo">{userInfo}</div>
                                    <div className="approve">{doc.appinstallyn}</div>
                                    <img src={Etc} alt='더보기' style={{width:'2.5rem',height:'2.5rem'}} 
                                        onClick={(e) => handleEtcClick(e, doc.id)}
                                    />
                                    {showMenu === doc.id && (
                                        <div 
                                            ref={menuRef}
                                            className="etc_area"
                                            style={{
                                                position: 'absolute',
                                                top: `${menuPosition.top}px`,
                                                right: `${menuPosition.right}px`,
                                                zIndex: 1000,
                                            }}
                                        >
                                            <button
                                                className="del mod"
                                                onClick={() => {
                                                    seasonoutStudent(doc.id, doc.groupid);
                                                    setShowMenu(null);
                                                }}
                                            >
                                                <img src={EtcMod} alt='시즌제외' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod">{doc.groupid === "noseason" ? '시즌처리' : '시즌제외'}</div>
                                            </button>
                                            <button
                                                className="del mod"
                                                onClick={() => {
                                                    newModStudent(doc.id, doc.appinstallyn);
                                                    setShowMenu(null);
                                                }}
                                            >
                                                <img src={EtcMod} alt='수정' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod">수정</div>
                                            </button>
                                            <button 
                                                className="del"
                                                onClick={() => {
                                                    delStudent(doc.id, doc.appinstallyn);
                                                }}
                                            >
                                                <img src={EtcDel} alt='삭제' style={{width:'1.5rem',height:'1.5rem'}} />
                                                <div className="text_mod del">삭제</div>
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )
                        }
                    )}
                </div>
                <div style={{height:'2rem'}} />
            </div>
        </>
    );
}

export default StudentMng;