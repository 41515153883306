import React, { useState, useEffect, useCallback, useRef } from 'react';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, Timestamp, orderBy } from "firebase/firestore";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import BtnSearch from '../images/buttons/search.png';
import BtnDown from '../images/buttons/arrow_down.png';
import BtnArrowDown from '../images/buttons/arrow_down_grey_16.png'
import StudyIcon from '../images/buttons/study_icon_mobile.svg';
import ArrowUpDown from '../images/buttons/arrow_updown_mobile.svg';
import SearchWhite from '../images/buttons/search_white_mobile.svg';
import Downward from '../images/buttons/downward_mobile.svg';


function StudyHistMng() {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    const year = now.getFullYear();
    const month = (now.getMonth()+1).toString();
    const day = now.getDate().toString();

    const [studyHistData, setStudyHistData] = useState([]);
    const [studyHistDataRaw, setStudyHistDataRaw] = useState([]);
    const [studentData, setStudentData] = useState([]);
    const [sortCriteria, setSortCriteria] = useState('seatno'); // 초기 정렬 기준은 좌석번호

    const [showCalendar, setShowCalendar] = useState(false);  //달력 보이기 기능
    const [calendarPosition, setCalendarPosition] = useState({ top: 0, left: 0 });
    const [displayDate, setDisplayDate] = useState(year+"년 "+month+"월 "+day+"일");
    const [selectedDate, setSelectedDate] = useState(now.getTime());

    const calendarRef = useRef(null);
    const searhNameRef = useRef(null);
    const searhNameRefTablet = useRef(null);
    const searhNameRefMobile = useRef(null);

    const [allSeasons, setAllSeasons] = useState([]);
    const [groupInfo, setGroupInfo] = useState([]);   //시즌 정보
    const [selSeason, setSelSeason] = useState("");  //선택 시즌 변수
    const [selGroup, setSelGroup] = useState("");  //선택 그룹 변수
    
    async function fetchAllSeasonInfo() {
        const groupQ = query(collection(db, "group"), orderBy("seq"));
        const groupSnapshot = await getDocs(groupQ);
    
        const groupData = groupSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
        setGroupInfo(groupData);
        setSelGroup(groupData[0].groupid);
    
        const seasonQ = query(collection(db, "seasoninfo"), orderBy("seq", 'desc'));
        const seasonSnapshot = await getDocs(seasonQ);
    
        const seasonData = seasonSnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
        }));
    
        return seasonData; // 모든 시즌 데이터 반환
    }
    
    function filterSeasonsByGroup(allSeasons, groupId) {
        const filteredSeasons = allSeasons.filter(season => season.groupid === groupId);
        
        // nowyn 필드가 true인 항목의 seasoncode를 찾아 setSelSeason에 설정
        const currentSeason = filteredSeasons.find(season => season.nowyn === true);
        if (currentSeason) {
            setSelSeason(currentSeason.seasoncode);
        }
    }

    //시즌 정보 코드
    useEffect(() => {
        async function fetchData() {
            const seasons = await fetchAllSeasonInfo();
            setAllSeasons(seasons);
        }
        fetchData();
    }, []);
    
    useEffect(() => {
        if (selGroup && allSeasons.length > 0) {
            filterSeasonsByGroup(allSeasons, selGroup);
        }
    }, [selGroup, allSeasons]);

    const callDb = useCallback(async () => {
        //학생 데이터
        const stuq = query(collection(db, "users"), where("confirmyn", "==", "Y"), 
            where("auth", "==", 1), where("groupid", "==", selSeason)
        );
        const stusnapshot = await getDocs(stuq);

        const stuData = stusnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        setStudentData(stuData);

        //학습일지 데이터
        const q = query(collection(db, "studyhistory"), where("studydate", "==", selectedDate),
                        where("groupid", "==", selSeason), orderBy("username"), orderBy("regtime"))
        
        const snapshot = await getDocs(q); 
        const queryData = snapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        // const filteredData = queryData.filter(student => {
        //     return stuData.some(studyhist => studyhist.userphone === student.userphone);
        // });
        
        // setStudyHistData(filteredData);
        // setStudyHistDataRaw(filteredData)

        // stdData의 seatno 필드 데이터를 queryData 추가
        const combinedData = queryData
            .filter(student => {
                return stuData.some(studyhist => studyhist.userphone === student.userphone);
            })
            .map((studyhist) => {
                const matchingStudent = stuData.find((student) => student.userphone === studyhist.userphone);
                return {
                ...studyhist,
                seatno: matchingStudent ? matchingStudent.seatno : "",
                };
        });
        setStudyHistData(combinedData);
        setStudyHistDataRaw(combinedData);
    }, [selectedDate, selSeason])

    useEffect(() => {
        callDb();
    },[callDb])

    const handleDateClick = (date) => {
        const selYear = date.getFullYear();
        const selMonth = (date.getMonth()+1).toString();
        const selDay = date.getDate().toString();
        const timestampDate = Timestamp.fromDate(date);
        setDisplayDate(selYear+"년 "+selMonth+"월 "+selDay+"일");
        setShowCalendar(!showCalendar);

        setSelectedDate(timestampDate.toMillis())
    };

    function ViewCalendar() {
        const currentDate = new Date(); // 현재 날짜로 설정
      
        return (
            <>
                {showCalendar && (
                    <div className="sliding_calendar" 
                        style={{ top: calendarPosition.top, left: calendarPosition.left }}
                        ref={calendarRef}
                    >
                        <Calendar
                            onChange={handleDateClick}
                            defaultActiveStartDate={currentDate}
                        />
                    </div>
                )}
            </>
        );
    }

    //이름조회 input 처리로직
    const handleSearch = (props) => {
        // const nameValue = searhNameRef.current.value;
        const nameValue = props;
        setStudyHistData(studyHistData);
        
        if(nameValue !== ""){
            const filteredCustomer = studyHistDataRaw.filter(item => item.username.includes(nameValue));
            setStudyHistData(filteredCustomer);
        }else{
            callDb();
        }
    }

    const handleSortClick = (criteria) => {
        setSortCriteria(criteria);
    };

    return (
        <>
            <div className="content_wrap">
                <div style={{width:"100%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div className="title_absent">학습일지관리</div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRef}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRef.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"100%",marginTop:"4.375rem",marginBottom:"1.25rem"}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"16.13rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6"}}
                        onClick={(e)=>{ 
                            const { top, left, height } = e.target.getBoundingClientRect();
                            const newTop = top + height; // 원하는 위치 조절
                            const newLeft = left; // 원하는 위치 조절
                            setCalendarPosition({ top: newTop, left: newLeft });
                            setShowCalendar(!showCalendar);
                        }}>
                        <div style={{color:"#0F5EFE",fontWeight:500,fontSize:"1.5rem",marginRight:"0.625rem"}}>{displayDate}</div>
                        <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                    </div> 
                </div>
                <ViewCalendar />
                <div style={{width:"100%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex'}}>
                    <select className="btn_midside" style={{width:"8.25rem",textAlign:'center'}} 
                            onChange={(e) => {
                                setSelGroup(e.target.value);
                            }}>
                        {groupInfo.map(group => (
                            <option key={group.groupid} value={group.groupid}>{group.groupname}</option>
                        ))}
                    </select>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('username')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"100%",height:"2.5rem",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"0.9375rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"2.5rem"}}>
                    <div style={{width:"9.375rem"}}>이름</div>
                    <div style={{width:"9.375rem"}}>좌석번호</div>
                    <div style={{width:"15.63rem"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"12.5rem"}}>과목</div>
                    <div style={{width:"12.5rem"}}>학습시간</div>
                    <div style={{width:"28.13rem",textAlign:'center'}}>비고</div>
                </div>
                {studyHistData
                    .sort((a, b) => {
                        if (sortCriteria === 'seatno') {
                            // 좌석번호 숫자를 비교
                            if (a.seatno !== "" && b.seatno !== "") {
                                return a.seatno - b.seatno;
                            } else if (a.seatno === "") {
                                return 1; // null은 뒤로 정렬
                            } else {
                                return -1; // null은 뒤로 정렬
                            }
                        } else if (sortCriteria === 'username') {
                            // 이름을 문자열로 비교
                            return a.username.localeCompare(b.username);
                        }
                        // 추가적인 정렬 기준이 필요한 경우 여기에 추가
                        return 0;
                    })
                    .map((doc) => {
                        const studytime = doc.studyhour+"시간 "+doc.studymin+"분"
                        const student = studentData.filter(item => item.userphone === doc.userphone);
                        
                        let userInfo;
                        if(student.length > 0){
                            const school = student[0].school;
                            const grade = student[0].grade;
                            const gender = student[0].gender;
                            
                            let genderTxt;
                            
                            if(gender === "M"){
                                genderTxt = "남";
                            }else if(gender === "F"){
                                genderTxt = "여";
                            }else{
                                genderTxt = "";
                            }

                            userInfo = school+" / "+grade+" / "+genderTxt;
                        } else {
                            userInfo = "";
                        }
                        
                        return (
                            <div style={{width:"100%",height:"2.5rem",display:'flex',
                                            color:"#191919",fontWeight:500,fontSize:"0.875rem",
                                            lineHeight:"2.5rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid",
                                        }} key={doc.id}>
                                <div style={{width:"9.375rem"}}>{doc.username}</div>
                                <div style={{width:"9.375rem"}}>{doc.seatno}</div>
                                <div style={{width:"15.63rem"}}>{userInfo}</div>
                                <div style={{width:"12.5rem"}}>{doc.subject}</div>
                                <div style={{width:"12.5rem"}}>{studytime}</div>
                                <div style={{width:"28.13rem"}}>{doc.studycontent}</div>
                            </div>
                        )
                    })
                }
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",display:'flex',justifyContent:"space-between",marginTop:"1.25rem"}}>
                    <div className="title_absent">학습일지관리</div>
                    <div style={{display:'flex',justifyContent:"space-between",width:"8.75rem",
                                    borderBottom:"#E9E9E9 1px solid",alignItems:'center',marginLeft:"0.9375rem"}}>
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefTablet}
                            style={{width:"6.25rem",fontWeight:400,fontSize:"0.75rem",color:"#A6A7AB"}}     
                        />
                        <img src={BtnSearch} className="btn_icon" style={{marginLeft:"0.8125rem",cursor:"pointer"}} alt='검색'
                            onClick={() => {handleSearch(searhNameRefTablet.current.value)}}
                        ></img>
                    </div>
                </div>
                <div style={{width:"95%",marginTop:"4.375rem",marginBottom:"1.25rem"}}>
                    <div style={{display:'flex',justifyContent:'center',alignItems:'center',width:"16.13rem",height:"4rem",borderRadius:"0.5rem",backgroundColor:"#F6F6F6"}}
                        onClick={(e)=>{ 
                            const { top, left, height } = e.target.getBoundingClientRect();
                            const newTop = top + height; // 원하는 위치 조절
                            const newLeft = left; // 원하는 위치 조절
                            setCalendarPosition({ top: newTop, left: newLeft });
                            setShowCalendar(!showCalendar);
                        }}>
                        <div style={{color:"#0F5EFE",fontWeight:500,fontSize:"1.5rem",marginRight:"0.625rem"}}>{displayDate}</div>
                        <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                    </div> 
                </div>
                <ViewCalendar />
                <div style={{width:"95%",marginTop:"1.25rem",marginBottom:"1.25rem",display:'flex'}}>
                    <select className="btn_midside" style={{width:"8.25rem",textAlign:'center'}} 
                            onChange={(e) => {
                                setSelGroup(e.target.value);
                            }}>
                        {groupInfo.map(group => (
                            <option key={group.groupid} value={group.groupid}>{group.groupname}</option>
                        ))}
                    </select>
                    <div className="btn_midside" style={{width:"6.25rem"}} onClick={() => handleSortClick('seatno')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        좌석번호순
                    </div>
                    <div className="btn_midside" style={{width:"5.375rem"}} onClick={() => handleSortClick('username')}>
                        <img src={BtnArrowDown} className="btn_midicon" alt='이름순' style={{width:"1rem",height:"1rem"}}/>
                        이름순
                    </div>
                </div>
                <div style={{width:"95%",height:"3rem",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"1rem",borderBottom:"#EEEEEE 1px solid",lineHeight:"3rem"}}>
                    <div style={{width:"10%"}}>이름</div>
                    <div style={{width:"10%"}}>좌석번호</div>
                    <div style={{width:"20%"}}>학교 / 학년 / 성별</div>
                    <div style={{width:"10%"}}>과목</div>
                    <div style={{width:"15%"}}>학습시간</div>
                    <div style={{width:"35%"}}>내역</div>
                </div>
                {studyHistData.map((doc) => {
                    const studytime = doc.studyhour+"시간 "+doc.studymin+"분"
                    const student = studentData.filter(item => item.userphone === doc.userphone);
                    
                    let userInfo;
                    if(student.length > 0){
                        const school = student[0].school;
                        const grade = student[0].grade;
                        const gender = student[0].gender;
                        
                        let genderTxt;
                        
                        if(gender === "M"){
                            genderTxt = "남";
                        }else if(gender === "F"){
                            genderTxt = "여";
                        }else{
                            genderTxt = "";
                        }

                        userInfo = school+" / "+grade+" / "+genderTxt;
                    } else {
                        userInfo = "";
                    }

                    return (
                        <div style={{width:"95%",height:"3rem",display:'flex',
                                        color:"#191919",fontWeight:400,fontSize:"15px",
                                        lineHeight:"3rem",alignItems:'center',borderBottom:"#EEEEEE 1px solid",
                                    }} key={doc.id}>
                            <div style={{width:"10%"}}>{doc.username}</div>
                            <div style={{width:"10%"}}>{doc.seatno}</div>
                            <div style={{width:"20%"}}>{userInfo}</div>
                            <div style={{width:"10%"}}>{doc.subject}</div>
                            <div style={{width:"15%"}}>{studytime}</div>
                            <div style={{width:"35%"}}>{doc.studycontent}</div>
                        </div>
                    )
                })}
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile padding_horizonal">
                <div className="sub_title attendmng">
                    <img src={StudyIcon} alt='학습일지관리' style={{width:'2.5rem',height:'2.5rem',marginRight:'0.62rem'}} />
                    <div>학습일지관리</div>
                </div>
                <div className="calendar_area"
                    onClick={(e)=>{ 
                        setCalendarPosition({ top: '17rem', left: '1.25rem' });
                        setShowCalendar(!showCalendar);
                }}>
                    <div className="calendar_text">{displayDate}</div>
                    <img src={BtnDown} style={{width:'1.5rem',height:'1.5rem'}} alt='기준일'></img>
                </div>
                <ViewCalendar />
                <div style={{display:'flex',justifyContent:'space-between',marginTop:'3.13rem'}}>
                    <div style={{display:'flex'}}>
                        <div className="button_season_sort">
                            <select className="season"  
                                    onChange={(e) => {
                                        setSelGroup(e.target.value);
                                    }}>
                                {groupInfo.map(group => (
                                    <option key={group.groupid} value={group.groupid}>{group.groupname}</option>
                                ))}
                            </select>
                            <img src={Downward} alt='그룹' style={{width:"1rem",height:"1rem"}} />
                        </div>
                        <div style={{width:'0.62rem'}} />
                        <div className="btn_midside" 
                            onClick={() => {
                                if(sortCriteria === "seatno"){
                                    handleSortClick('stdname')
                                }else{
                                    handleSortClick('seatno')
                                }
                            }}
                        >
                            {sortCriteria === "seatno" ? "좌석순"
                            : "이름순"
                            }
                            <img src={ArrowUpDown} alt='좌석번호순' style={{width:"1rem",height:"1rem"}}/>
                        </div>
                    </div>
                    <div className="search_name_area">
                        <input name="search_input" type="text" placeholder="이름" ref={searhNameRefMobile}/>
                        <div className="button_area">
                            <img src={SearchWhite} style={{width:'0.9375rem',height:'0.99613rem'}} alt='검색'
                                onClick={() => {handleSearch(searhNameRefMobile.current.value)}}
                            ></img>
                        </div>
                    </div>
                </div>
                <div className="header_area_mobile_container">
                    <div className="header_area_mobile">
                        <div className="name">이름</div>
                        <div className="seat">좌석</div>
                        <div className="personalinfo">학교/학년/성별</div>
                        <div className="subject">과목</div>
                        <div className="studytime">학습시간</div>
                        <div className="comment">내역</div>
                    </div>
                    {studyHistData.map((doc) => {
                        const studytime = doc.studyhour+"시간 "+doc.studymin+"분"
                        const student = studentData.filter(item => item.userphone === doc.userphone);
                        
                        let userInfo;
                        if(student.length > 0){
                            const school = student[0].school;
                            const grade = student[0].grade;
                            const gender = student[0].gender;
                            
                            let genderTxt;
                            
                            if(gender === "M"){
                                genderTxt = "남";
                            }else if(gender === "F"){
                                genderTxt = "여";
                            }else{
                                genderTxt = "";
                            }

                            userInfo = school+" / "+grade+" / "+genderTxt;
                        } else {
                            userInfo = "";
                        }

                        return (
                            <div className="body_area_mobile" key={doc.id}>
                                <div className="name">{doc.username}</div>
                                <div className="seat">
                                    <div className="seatno">{doc.seatno}</div>
                                </div>
                                <div className="personalinfo">{userInfo}</div>
                                <div className="subject">{doc.subject}</div>
                                <div className="studytime">{studytime}</div>
                                <div className="comment">{doc.studycontent}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    );
}

export default StudyHistMng;