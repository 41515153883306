import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, getDocs, where, updateDoc, doc, Timestamp } from "firebase/firestore";
import Swal from "sweetalert2";
import { ImageMap } from '@qiuz/react-image-map';
import Seats2F from '../images/sd_seats_2f.svg'
import Seats3F from '../images/sd_seats_3f.svg'
import BtnClose from '../images/buttons/close.png'
import ArrowDown from '../images/buttons/arrow_down.png'

const mapArea2F = [
    //1
    {
        left: '28%',
        top: '2.3%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //2
    {
        left: '37.7%',
        top: '2.3%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //3
    {
        left: '54.7%',
        top: '2.3%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //4
    {
        left: '64.4%',
        top: '2.3%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //5
    {
        left: '73.9%',
        top: '2.3%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //6
    {
        left: '90%',
        top: '7.2%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //7
    {
        left: '90%',
        top: '15%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //8
    {
        left: '90%',
        top: '23%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //9
    {
        left: '90%',
        top: '31%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //10
    {
        left: '90%',
        top: '39%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //11
    {
        left: '90%',
        top: '50%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //12
    {
        left: '90%',
        top: '58%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //13
    {
        left: '90%',
        top: '66%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //14
    {
        left: '90%',
        top: '74%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //15
    {
        left: '90%',
        top: '82%',
        height: '6.6%',
        width: '5.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //16
    {
        left: '73.5%',
        top: '91.7%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //17
    {
        left: '64.2%',
        top: '91.7%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //18
    {
        left: '55%',
        top: '91.7%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //19
    {
        left: '37.6%',
        top: '91.7%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //20
    {
        left: '28.4%',
        top: '91.7%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //21
    {
        left: '18.4%',
        top: '91.7%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //22
    {
        left: '9%',
        top: '91.7%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //23
    {
        left: '9%',
        top: '73%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //24
    {
        left: '18.4%',
        top: '73%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //25
    {
        left: '45.4%',
        top: '73%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //26
    {
        left: '55%',
        top: '73%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //27
    {
        left: '64.4%',
        top: '73%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //28
    {
        left: '64.4%',
        top: '66.8%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //29
    {
        left: '55%',
        top: '66.8%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //30
    {
        left: '45.4%',
        top: '66.8%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //31
    {
        left: '45.4%',
        top: '50%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //32
    {
        left: '55%',
        top: '50%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //33
    {
        left: '64.4%',
        top: '50%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //34
    {
        left: '64.4%',
        top: '44%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //35
    {
        left: '55%',
        top: '44%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //36
    {
        left: '45.4%',
        top: '44%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //37
    {
        left: '45.4%',
        top: '23%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //38
    {
        left: '55%',
        top: '23%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //39
    {
        left: '64.4%',
        top: '23%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //40
    {
        left: '64.4%',
        top: '17%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //41
    {
        left: '55%',
        top: '17%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //42
    {
        left: '45.4%',
        top: '17%',
        height: '5%',
        width: '8%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
];

const mapArea3F = [
    //43
    {
        left: '66%',
        top: '89.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //44
    {
        left: '55.8%',
        top: '89.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //45
    {
        left: '45.5%',
        top: '89.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //46
    {
        left: '35.3%',
        top: '89.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //47
    {
        left: '25%',
        top: '89.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //48
    {
        left: '14.5%',
        top: '89.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //49
    {
        left: '4.5%',
        top: '77.8%',
        height: '7.4%',
        width: '6.2%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //50
    {
        left: '4.5%',
        top: '68.6%',
        height: '7.4%',
        width: '6.2%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //51
    {
        left: '4.5%',
        top: '50.2%',
        height: '7.4%',
        width: '6.2%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //52
    {
        left: '4.5%',
        top: '41%',
        height: '7.4%',
        width: '6.2%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //53
    {
        left: '4.5%',
        top: '31.9%',
        height: '7.4%',
        width: '6.2%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //54
    {
        left: '4.5%',
        top: '22.6%',
        height: '7.4%',
        width: '6.2%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //55
    {
        left: '4.5%',
        top: '13.5%',
        height: '7.4%',
        width: '6.2%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //56
    {
        left: '15%',
        top: '4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //57
    {
        left: '25.3%',
        top: '4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //58
    {
        left: '35.6%',
        top: '4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //59
    {
        left: '56.1%',
        top: '4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //60
    {
        left: '66.4%',
        top: '4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //61
    {
        left: '76.7%',
        top: '4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //62
    {
        left: '86.9%',
        top: '4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //63
    {
        left: '86.9%',
        top: '33%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //64
    {
        left: '76.6%',
        top: '33%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //65
    {
        left: '45.4%',
        top: '23.6%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //66
    {
        left: '35.1%',
        top: '23.6%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //67
    {
        left: '25%',
        top: '23.6%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //68
    {
        left: '25%',
        top: '33%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //69
    {
        left: '35.1%',
        top: '33%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //70
    {
        left: '45.4%',
        top: '33%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //71
    {
        left: '45.4%',
        top: '60.4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //72
    {
        left: '35.1%',
        top: '60.4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //73
    {
        left: '25%',
        top: '60.4%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //74
    {
        left: '25%',
        top: '69.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //75
    {
        left: '35.1%',
        top: '69.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
    //76
    {
        left: '45.4%',
        top: '69.7%',
        height: '5.4%',
        width: '8.5%',
        style: { borderRadius: '0.375rem', cursor: 'pointer' },
    },
];

function AbsenteeMng() {
    const navigate = useNavigate();

    // 컴포넌트 내부에 상태 추가
    const [selectedFloor, setSelectedFloor] = useState('2F'); // 초기값 3층

    const [studentData, setStudentData] = useState([]);
    const [attendData, setAttendData] = useState([]);
    const [scheduleData, setScheduleData] = useState([]);

    const [modalParam, setModalParam] = useState(null);
    const [modalStdInfo, setModalStdInfo] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false); 
    const [isSelectOpen, setIsSelectOpen] = useState(false);    

    const [mapArea, setMapArea] = useState(mapArea2F);

    // 층별 이미지 소스 처리
    const floorImages = {
        '2F': Seats2F,
        '3F': Seats3F
    };

    const callDb = useCallback(async () => {
        const today = new Date();
        const startOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0);
        const endOfDay = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);

        const startTimestamp = Timestamp.fromDate(startOfDay);
        const endTimestamp = Timestamp.fromDate(endOfDay);
        const nowTimestamp = today.getTime();

        //학생 데이터
        const stq = query(collection(db, "users"), where("groupid", "==", "sd4"),
                    where("membertype", "==", "C"), where("seatno", "!=", ""),
                )
        
        const stsnapshot = await getDocs(stq);

        const stqueryData = stsnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id,
            statusflag: 0,
            scheduleinfo: ""    
        }));

        setStudentData(stqueryData);

        //출석 데이터
        const attq = query(collection(db, "attendance"), where("groupid", "==", "sd4"),
                    where("entertime", ">=", startTimestamp), where("entertime", "<=", endTimestamp)
                )
        const attsnapshot = await getDocs(attq);

        const attqueryData = attsnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        setAttendData(attqueryData);

        //부재일정 데이터
        const schq = query(collection(db, "schedules"), where("groupid", "==", "sd4"),
                    where("daystart", "<=", nowTimestamp)
                )
        const schsnapshot = await getDocs(schq);

        const schqueryData = schsnapshot.docs.map(doc => ({
            ...doc.data(),
            id: doc.id
        }));

        const hour = today.getHours().toString().padStart(2, '0');
        const min = today.getMinutes().toString().padStart(2, '0');

        const stdTime = hour+min;

        //오늘의 요일을 계산(일:0 월:1 화:2 ..)
        //계산된 요일값에 따라 일정테이블 데이터의 요일값에 대한 필터 적용하는 로직
        const day = today.getDay();
        const filteredData = schqueryData.filter(item => 
            item.dayend >= nowTimestamp &&
            item.starttime <= stdTime &&
            item.endtime >= stdTime
        )
        
        let dayFilteredData;
        if(day === 0){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.sunday === true 
                )
            })
        }else if(day === 1){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.monday === true 
                )
            })
        }else if(day === 2){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.tuesday === true 
                )
            })
        }else if(day === 3){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.wednesday === true 
                )
            })
        }else if(day === 4){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.thursday === true 
                )
            })
        }else if(day === 5){
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.friday === true 
                )
            })
        }else{
            dayFilteredData = filteredData.filter(item =>{
                return(
                    item.saturday === true 
                )
            })
        }
        setScheduleData(dayFilteredData);
    }, [])

    useEffect(() => {
        callDb();
    },[callDb])

    useEffect(() => {
        if (studentData.length > 0) {
            const updatedStudentData = studentData.map((student) => {
                const attendEntry = attendData.find((attend) => attend.stdphone === student.userphone && attend.completeyn === "N");
                const attendExit = attendData.find((attend) => attend.stdphone === student.userphone && attend.completeyn === "Y");
                const scheduleNow = scheduleData.find((schedule) => schedule.userphone === student.userphone);
                const unauthabsEntry = attendData.find((attend) => attend.stdphone === student.userphone && attend.unauthabsence === "Y");
            
                let statusflag;
                let scheduleinfo;
                if (attendEntry) {
                    if (scheduleNow) {
                        statusflag = 4; // 보라색
                        scheduleinfo = scheduleNow.title;
                    } else if (unauthabsEntry) {
                        statusflag = 5; // 노란색
                    } else {
                        statusflag = 2; // 초록색
                    }
                } else if (attendExit) {
                    statusflag = 3; // 회색
                } else {
                    statusflag = 1; // 빨간색
                }
            
                return { ...student, statusflag, scheduleinfo };
            });
        
            setStudentData(updatedStudentData);
        }
    }, [attendData, scheduleData]);
    
    useEffect(() => {
        if (studentData.length > 0) {
            const updatedMapArea = mapArea.map((area, index) => {
                let student;

                if(selectedFloor === '2F') {
                    student = studentData.find((student) => student.seatno === (index+1).toString());
                } else if(selectedFloor === '3F') {
                    student = studentData.find((student) => student.seatno === (index+43).toString());
                }
                
                if (student) {
                let color;

                switch (student.statusflag) {
                case 4:
                color = 'rgba(121, 98, 246, 0.60)'; // 보라색
                break;
                case 5:
                color = 'rgba(254, 235, 63, 0.60)'; // 노란색
                break;
                case 2:
                color = 'rgba(20, 202, 38, 0.60)'; // 초록색
                break;
                case 3:
                color = 'rgba(185, 185, 185, 0.60)'; // 회색
                break;
                case 1:
                color = 'rgba(254, 86, 63, 0.60)'; // 빨간색
                break;
                default:
                color = 'transparent'; // 무색
                }
                return { ...area, style: { ...area.style, background: color } };
                }
                return area;
            });
            setMapArea(updatedMapArea);
        }
    }, [studentData, selectedFloor]);

    const processUnauthAbsence = async (colId, unauthAbs) => {
        try{
            const now = new Date();
            const year = now.getFullYear();
            const month = now.getMonth()+1;
            const date = now.getDate();

            const docRef = doc(db, "attendance", colId)
            await updateDoc(docRef, {
                unauthabsence: unauthAbs,
                unauthregtime: now,
                unauthdate: year.toString()+"-"+month.toString()+"-"+date.toString(),
            });

            if(unauthAbs === "Y"){
                Swal.fire({
                    icon: "success",
                    title: "처리 완료",
                    text: "무단부재자 등록이 완료되었습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        setIsModalOpen(false)
                        callDb();
                    }
                });
            }else if(unauthAbs === "N"){
                Swal.fire({
                    icon: "success",
                    title: "처리 완료",
                    text: "무단부재자 해제가 완료되었습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        //확인 요청 처리
                        setIsModalOpen(false)
                        callDb();
                    }
                });
            }
        }catch(error){
            console.log(error.message);
        }
    }

    const toUnauthAbsMng = () => {
        navigate("/unauthabsenteemng", {
            state: {
                studentdata: studentData,
            },
        })
    }

    const onMapClick = (area, index) => {
        setIsModalOpen(true);
        let student;

        if(selectedFloor === '2F') {
            student = studentData.find((student) => student.seatno === (index+1).toString());
        } else if(selectedFloor === '3F') {
            student = studentData.find((student) => student.seatno === (index+43).toString());
        }

        if(student){
            const attendance = attendData.find((attend) => attend.stdphone === student.userphone && attend.completeyn === "N");
            setModalStdInfo(student);
            setModalParam(attendance);
        } else {
            setModalStdInfo(null);
            setModalParam(null);
        }
    };

    const handleSelectClick = () => {
        setIsSelectOpen(!isSelectOpen);
    };

    const handleChageSeat = (oriseatno, chgseatno) => {
        setIsSelectOpen(!isSelectOpen);
        const oriStudent = studentData.find((student) => student.seatno === oriseatno.toString());
        const chgStudent = studentData.find((student) => student.seatno === chgseatno.toString());

        if(chgStudent){
            const title = `${chgStudent.username} 님이 사용 중입니다.`;

            Swal.fire({
                icon: "question",
                title: title,
                text: "좌석을 교체 하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "교체",
                cancelButtonText: "취소",
            }).then(async (res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                    const oridocRef = doc(db, "users", oriStudent.id)
                    await updateDoc(oridocRef, {
                        seatno: chgseatno.toString(),
                    });

                    const chgdocRef = doc(db, "users", chgStudent.id)
                    await updateDoc(chgdocRef, {
                        seatno: oriseatno.toString(),
                    });

                    Swal.fire({
                        icon: "success",
                        title: "처리 완료",
                        text: "좌석 변경이 완료되었습니다",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            setIsModalOpen(false)
                            callDb();
                            // setUpdateState(!updateState);   //스테이트 바꿔서 useEffect 강제호출
                        }
                    });
                }
            });
        }else {
            Swal.fire({
                icon: "question",
                title: "좌석 변경",
                text: "좌석을 변경 하시겠습니까?",
                showCancelButton: true,
                confirmButtonText: "변경",
                cancelButtonText: "취소",
            }).then(async (res) => {
                /* Read more about isConfirmed, isDenied below */
                if (res.isConfirmed) {
                    //확인 요청 처리
                    setStudentData(prev => 
                        prev.filter(student => student.seatno !== oriseatno.toString())
                    );

                    const oridocRef = doc(db, "users", oriStudent.id)
                    await updateDoc(oridocRef, {
                        seatno: chgseatno.toString(),
                    });

                    setMapArea(prev => prev.map(area => ({
                        ...area,
                        style: { ...area.style, background: 'transparent' }
                    })));

                    callDb();
                    
                    Swal.fire({
                        icon: "success",
                        title: "처리 완료",
                        text: "좌석 변경이 완료되었습니다",
                        //showCancelButton: true,
                        confirmButtonText: "확인",
                        //cancelButtonText: "취소",
                    }).then((res) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (res.isConfirmed) {
                            //확인 요청 처리
                            setIsModalOpen(false)
                        }
                    });
                }
            });
        }
    }

    // 층 변경 핸들러
    const handleFloorChange = (floor) => {
        setSelectedFloor(floor);
        // 필요시 층별 mapArea 데이터도 변경
        setMapArea(floor === '2F' ? mapArea2F : mapArea3F);
        callDb();
    };

    return (
        <div className="content_wrap">
            <div style={{width:"100%",display:'flex',marginTop:"1.25rem"}}>
                <div className="title_absent">좌석관리</div>
                <div className="title_absent" style={{marginLeft:"0.5rem",marginRight:"0.5rem",color:"#C1C1C1"}}>·</div>
                <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}
                    onClick={toUnauthAbsMng}
                >무단부재자관리</div>
            </div>
            <div style={{display:'flex',width:'100%'}}>
                <div style={{display:'flex',marginRight:'9rem',paddingTop:'2rem'}}>
                    <div className='absentee_floor_sel'
                        onClick={() => handleFloorChange('2F')}
                        style={{ 
                          color: selectedFloor === '2F' ? '#000' : '#C1C1C1',
                          cursor: 'pointer'
                        }}
                    >시대정신 2층</div>
                    <div className='absentee_floor_sel' 
                        onClick={() => handleFloorChange('3F')}
                        style={{
                          color: selectedFloor === '3F' ? '#000' : '#C1C1C1',
                          marginLeft: '1.13rem',
                          cursor: 'pointer'
                        }}
                    >시대정신 3층</div>
                </div>
                <div>
                    <ImageMap
                        // className="usage-map"
                        style={{width:"auto",height:"75vh",minHeight:700}}
                        src={floorImages[selectedFloor]}
                        map={mapArea}
                        onMapClick={onMapClick}
                    />
                </div>
                <div style={{marginLeft:'9.81rem'}}>
                    <div className='flexcenter' style={{marginBottom:'0.75rem'}}>
                        <div className='absentee_color_info' style={{background:'#14CA26'}}></div>
                        <div className='absentee_color_info_text'>입실</div>
                    </div>
                    <div className='flexcenter' style={{marginBottom:'0.75rem'}}>
                        <div className='absentee_color_info' style={{background:'#B9B9B9'}}></div>
                        <div className='absentee_color_info_text'>퇴실</div>
                    </div>
                    <div className='flexcenter' style={{marginBottom:'0.75rem'}}>
                        <div className='absentee_color_info' style={{background:'#FE563F'}}></div>
                        <div className='absentee_color_info_text'>결석</div>
                    </div>
                    <div className='flexcenter' style={{marginBottom:'0.75rem'}}>
                        <div className='absentee_color_info' style={{background:'#7962F6'}}></div>
                        <div className='absentee_color_info_text'>부재</div>
                    </div>
                    <div className='flexcenter'>
                        <div className='absentee_color_info' style={{background:'#FEEB3F'}}></div>
                        <div className='absentee_color_info_text'>무단부재</div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="absentee_modal_overlay">
                    <div className='absentee_modal_content'>
                        <div className='flexcenter' style={{width:'100%',justifyContent:'space-between'}}>
                            <div className='flexcenter'>
                            {modalStdInfo && modalStdInfo.username ?
                                <div className='absentee_modal_stdname'>{modalStdInfo.username}</div>
                            : 
                                <div className='absentee_modal_stdname'>미지정 좌석</div>
                            }
                            {modalStdInfo && modalStdInfo.seatno ? 
                                <div className='absentee_modal_seatno'>{modalStdInfo.seatno}</div> : 
                                null
                            }
                            </div>
                            <img src={BtnClose} className="btn_1dot5rem" alt='닫기' style={{cursor:'pointer'}}
                                onClick={()=>{
                                    setIsModalOpen(false)
                                    setIsSelectOpen(false)
                                }}
                            ></img>
                        </div>
                        {modalStdInfo && modalStdInfo.statusflag ? 
                            <div>
                            {modalStdInfo.statusflag === 1 ?
                                <div className='absentee_modal_status'>결석</div>
                            : modalStdInfo.statusflag === 2 ?
                                <div className='absentee_modal_status'>입실</div>
                            : modalStdInfo.statusflag === 3 ?
                                <div className='absentee_modal_status'>퇴실</div>
                            : modalStdInfo.statusflag === 4 ?
                                <div className='absentee_modal_status'>
                                    {modalStdInfo.scheduleinfo.length > 25 ? 
                                    `${modalStdInfo.scheduleinfo.substring(0, 25)}...` : 
                                    modalStdInfo.scheduleinfo}
                                </div>
                            : 
                                <div className='absentee_modal_status'>무단부재</div>
                            }
                                <div className='flexcenter'>
                                    <div className='absentee_btn_modal' style={{marginRight:'1rem'}}
                                        onClick={handleSelectClick}
                                    >
                                        <div>좌석변경</div>
                                        <img src={ArrowDown} className="btn_1rem" alt='좌석변경' />
                                    </div>
                                    {isSelectOpen && (
                                    <div className="absentee_modal_select">
                                        {Array.from({length: 76}, (_, i) => i + 1).map((seat) => (
                                        <div key={seat} style={{cursor:'pointer'}}
                                            onClick={()=>{handleChageSeat(modalStdInfo.seatno, seat)}}>{seat}
                                        </div>
                                        ))}
                                    </div>
                                    )}
                                    <div>
                                    {modalStdInfo.statusflag === 2 ?
                                        <button className='absentee_btn_modal' style={{color:'#FF594F'}}
                                            onClick={()=>{processUnauthAbsence(modalParam.id, "Y")}}
                                        >무단부재자 처리</button>
                                    : modalStdInfo.statusflag === 5 && 
                                        <button className='absentee_btn_modal' style={{color:'#FF594F'}}
                                            onClick={()=>{processUnauthAbsence(modalParam.id, "N")}}
                                        >무단부재자 해제</button>
                                    }
                                    </div>
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
            )}
            <footer>
                <div className="copyright" style={{marginTop:"6.25rem",marginBottom:"3.125rem"}}>
                    <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                </div>
            </footer>
        </div>
    );
}

export default AbsenteeMng;