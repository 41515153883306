import { db } from './firebase_config.js';
import { doc, setDoc, getDocs, collection, where, query } from "firebase/firestore";

const sendPushMessage = async (props) => {
  const { token, notification, data } = props;
  
  try {
    const response = await fetch('https://us-central1-sdacademy-a5ed3.cloudfunctions.net/api/send-notification', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token, notification, data }),
    });

    if (response.ok) {
      console.log('Push notification sent successfully.');
    } else {
      console.error('Failed to send push notification:', await response.text());
    }
  } catch (error) {
    console.error('Error sending push notification:', error);
  }
}

export const findParentData = async (registerUserphone, registerPphone) => {
  try {
    const usersRef = collection(db, "users");
    const q = query(
      usersRef, 
      where("userphone", "==", registerPphone),
      where("parentphone", "==", registerUserphone),
      where("membertype", "==", "P"),
    );
    
    const querySnapshot = await getDocs(q);
    
    if (!querySnapshot.empty) {
      return querySnapshot.docs[0].data();
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error finding parent data: ", error);
  }
};

export const sendPushToSelected = async (recieverinfo, page, title, body) => {
  console.log('Received parameters:', { recieverinfo, page, title, body });
  try {
    const users = Array.isArray(recieverinfo) ? recieverinfo : [recieverinfo];
    const tokens = users
      // .filter(user => user.alarmyn === true)  // alarmyn이 true인 사용자만 필터링
      .map(user => user.token);

    const payload = {
      notification: {
          title: title,
          body: body,
      },
      data: {
          screen: page,
      }
    };
    console.log('Received users:', tokens);
    for (const token of tokens) {
      await sendPushMessage({ ...payload, token });
    }
    console.log('푸시 메시지를 보냈습니다.');
  } catch (error) {
    console.error('푸시 메시지 전송 중 오류 발생:', error);
  }
};

const registReceiverData = (dataId, now, recieverinfo) => {
  // recieverinfo가 배열인지 확인
  const users = Array.isArray(recieverinfo) ? recieverinfo : [recieverinfo];

  users.forEach(async user => {
    const docId = dataId+user.userphone;
    const docRef = doc(db, "receivers", docId);

    // 데이터베이스에 저장할 객체 생성
    const receiverData = {
      origin: dataId,
      regdate: now,
      readyn: "N",
      readdate: "",
      destination: user.username,
      destphone: user.userphone,
      membertype: user.membertype,
      seatno: user.seatno,
    };

    try { 
        await setDoc(docRef, receiverData);
    } catch (error) {
        console.error("Error adding document: ", error);
    }
  });
}

export const registMessageData = async (title, content, userid, code, cnt, recieverinfo) => {
  //접속자 정보
  const userQuery = query(collection(db, "users"), where("userid", "==", userid));
  const userSnapshot = await getDocs(userQuery);
  const userData = userSnapshot.docs.map((doc) => {
      return {
          ...doc.data(),
          id: doc.id,
      };
  });  
  const userId = userData[0].userid;
  const userName = userData[0].username;
  const userPhone = userData[0].userphone;

  // 데이터의 개수 가져오기
  const snapshot = await getDocs(query(collection(db, "messages"), where("code", "==", code)));
  const count = snapshot.size+1;

  const dataId = code+count
  const docRef = doc(db, "messages", dataId);
  const now = new Date();

  // 데이터베이스에 저장할 객체 생성
  const messageData = {
    docid: dataId,
    code: code,
    seq: count,
    userid: userId,
    username: userName,
    userphone: userPhone,
    title: title,
    content: content,
    regdate: now,
    receiverscnt: cnt,
  };

  // 데이터베이스에 저장
  try { 
    await setDoc(docRef, messageData);
    registReceiverData(dataId, now, recieverinfo);
  } catch (error) {
      console.error("Error adding document: ", error);
  }
}

