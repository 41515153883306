import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase_config.js';
import { query, collection, where, getDocs, doc, setDoc, orderBy } from "firebase/firestore";
import { registMessageData, sendPushToSelected } from '../SendPushMessage.js';
import Swal from "sweetalert2";
import StudyIcon from '../images/buttons/study_icon_mobile.svg';
import BtnSearch from '../images/buttons/search_white.svg';
import BtnClose from '../images/buttons/close.png'
import BtnSort from '../images/buttons/sort.svg';
import UnRead from '../images/buttons/markunread.svg';
import Checkbox from '../images/buttons/checkbox.svg';
import CheckboxSel from '../images/buttons/checkboxsel.svg';
import ArrowRight from '../images/buttons/arrow_right.svg';
import ArrowLeft from '../images/buttons/arrow_left.svg';
import MiniClose from '../images/buttons/miniclose.svg';

function MessageWrite(props) {
    const navigate = useNavigate();
    const userInfo = props.userAuth;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedLeftIds, setSelectedLeftIds] = useState([]);
    const [selectedRightIds, setSelectedRightIds] = useState([]);
    const [isAllLeftSelected, setIsAllLeftSelected] = useState(false);
    const [isAllRightSelected, setIsAllRightSelected] = useState(false);
    const [selSeason, setSelSeason] = useState("");  //선택 시즌 변수
    const [selMemberType, setSelMemberType] = useState('A');
    const [sortOption, setSortOption] = useState('seat'); // 기본 정렬 옵션
    const [searchTerm, setSearchTerm] = useState('');

    const leftScrollRef = useRef(null);
    const rightScrollRef = useRef(null);
    const scrollLeftPositionRef = useRef(0);
    const scrollRightPositionRef = useRef(0);
    const searhNameRef = useRef(null);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    useEffect(() => {
        async function fetchSeasonInfo() {
            const seasonQ = query(collection(db, "seasoninfo"), orderBy("seq", 'desc'));
            const seasonSnapshot = await getDocs(seasonQ);
    
            const seasonData = seasonSnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                    id: doc.id,
                };
            });
    
            // nowyn이 true인 문서 찾기
            const currentSeason = seasonData.find(season => season.nowyn === true);
            if (currentSeason) {
                setSelSeason(currentSeason.seasoncode);
            }
        }
    
        fetchSeasonInfo();
    }, []);

    useEffect(() => {
        async function fetchUsers() {
            let q = query(
                collection(db, "users"),
                where("groupid", "==", selSeason),
            );
    
            const querySnapshot = await getDocs(q);
            let allUserData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                parenttype: null
            }));
            
            // parenttype 필드 처리
            let processedUserData = allUserData.map(user => {
                if (user.membertype === 'P') {
                    const childUser = allUserData.find(otherUser => 
                        otherUser.id !== user.id && 
                        user.userphone === otherUser.parentphone &&
                        user.parentphone === otherUser.userphone
                    );
                    if (childUser) {
                        user.parenttype = '학부모';
                        user.username = childUser.username;
                        user.seatno = childUser.seatno;
                        return user;
                    }
                    return null;
                }
                return user;
            }).filter(user => user !== null);

            // selMemberType에 따라 필터링
            if (selMemberType !== 'A') {
                processedUserData = processedUserData.filter(user => user.membertype === selMemberType);
            }

            // 좌석번호로 정렬
            processedUserData.sort((a, b) => {
                const getSeatNumber = (seatno) => {
                    if (!seatno) return Infinity;
                    const num = parseInt(seatno.replace(/\D/g, ''), 10);
                    return isNaN(num) ? Infinity : num;
                };

                const seatA = getSeatNumber(a.seatno);
                const seatB = getSeatNumber(b.seatno);

                if (seatA === seatB) {
                    return a.seatno.localeCompare(b.seatno);
                }
                return seatA - seatB;
            });

            setUsers(processedUserData);
        }
    
        fetchUsers();
    }, [selSeason, selMemberType]);

    useEffect(() => {
        // 컴포넌트 업데이트 후 스크롤 위치 복원
        if (leftScrollRef.current) {
            leftScrollRef.current.scrollTop = scrollLeftPositionRef.current;
        }
        if (rightScrollRef.current) {
            rightScrollRef.current.scrollTop = scrollRightPositionRef.current;
        }
    }, [selectedLeftIds, selectedRightIds]);

    let initialTitle = "";
    let initialContent = "";

    const [title, setTitle] = useState(initialTitle !== "" ? initialTitle : "");
    const [content, setContent] = useState(initialContent !== "" ? initialContent : "");

    // 메세지 내용 저장 및 푸시 발송 함수
    const makeMessageData = async () => {
        if(title !== "" && content !== "" && selectedUsers.length > 0) {
            // 데이터베이스에 저장
            try { 
                registMessageData(title, content, userInfo.email, "msg", selectedUsers.length, selectedUsers);
                sendPushToSelected(selectedUsers, "MessageMng", '메시지 도착', '새로운 메시지가 도착했습니다.');
                
                Swal.fire({
                    icon: "success",
                    title: "메시지발송 완료",
                    text: "메시지 발송이 완료되었습니다",
                    //showCancelButton: true,
                    confirmButtonText: "확인",
                    //cancelButtonText: "취소",
                }).then((res) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (res.isConfirmed) {
                        navigate("/messagemng");
                    }
                });
                
            } catch (error) {
                console.error("Error adding document: ", error);
                showAlert("error", "메시지 데이터등록 실패", "다시 시도해주세요.");
            }
        } else {
            showAlert("error", "발송 실패", "제목 내용 발송대상은 필수값입니다.");
        }
    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };

    const showAlert = (errDiv, errTitle, errMsg) => {
        Swal.fire({
            icon: errDiv,
            title: errTitle,
            text: errMsg,
            confirmButtonText: "확인",
        }).then((res) => {
            if (res.isConfirmed) {
                // 확인 요청 처리
            }
        });
    };

    const delselecteduser = (userId) => {
        // selectedUsers에서 해당 사용자를 찾습니다
        const userToRemove = selectedUsers.find(user => user.id === userId);
      
        if (userToRemove) {
            // selectedUsers에서 해당 사용자를 제거합니다
            setSelectedUsers(prevSelected => prevSelected.filter(user => user.id !== userId));
        
            // users에 해당 사용자를 추가합니다
            setUsers(prevUsers => [...prevUsers, userToRemove]);
        }
    };

    function ShowModal({ closeModal }) {
        // 정렬 함수
        const sortUsers = (option) => {
            let sortedUsers = [...users];
            if (option === 'name') {
                sortedUsers.sort((a, b) => a.username.localeCompare(b.username));
            } else if (option === 'seat') {
                sortedUsers.sort((a, b) => {
                    const getSeatNumber = (seatno) => {
                        if (!seatno) return Infinity;
                        const num = parseInt(seatno.replace(/\D/g, ''), 10);
                        return isNaN(num) ? Infinity : num;
                    };

                    const seatA = getSeatNumber(a.seatno);
                    const seatB = getSeatNumber(b.seatno);

                    if (seatA === seatB) {
                        return a.seatno.localeCompare(b.seatno);
                    }
                    return seatA - seatB;
                });
            }
            setUsers(sortedUsers);
        };

        // select 변경 핸들러
        const handleSortChange = (e) => {
            const option = e.target.value;
            setSortOption(option);
            sortUsers(option);
        };

        const handleSelectLeft = (user) => {
            // 현재 스크롤 위치 저장
            scrollLeftPositionRef.current = leftScrollRef.current.scrollTop;
            
            setSelectedLeftIds(prev => 
              prev.includes(user.id) 
                ? prev.filter(id => id !== user.id)
                : [...prev, user.id]
            );
        };
    
        const handleSelectRight = (user) => {
            // 현재 스크롤 위치 저장
            scrollRightPositionRef.current = rightScrollRef.current.scrollTop;

            setSelectedRightIds(prev => 
                prev.includes(user.id) 
                  ? prev.filter(id => id !== user.id) 
                  : [...prev, user.id]
            );
        };

        const handleBulkLeftSelect = () => {
            if (isAllLeftSelected) {
                // 모두 선택 해제
                setSelectedLeftIds([]);
            } else {
                // 모두 선택
                setSelectedLeftIds(users.map(user => user.id));
            }
            setIsAllLeftSelected(!isAllLeftSelected);
        };

        const handleBulkRightSelect = () => {
            if (isAllRightSelected) {
                // 모두 선택 해제
                setSelectedRightIds([]);
            } else {
                // 모두 선택
                setSelectedRightIds(selectedUsers.map(user => user.id));
            }
            setIsAllRightSelected(!isAllRightSelected);
        };
    
        const moveToRight = () => {
            const newSelected = users.filter(user => selectedLeftIds.includes(user.id));
            setSelectedUsers(prev => [...prev, ...newSelected]);
            setUsers(prev => prev.filter(user => !selectedLeftIds.includes(user.id)));
            setSelectedLeftIds([]);
            setIsAllLeftSelected(false);
        };
    
        const moveToLeft = () => {
            const newUnselected = selectedUsers.filter(user => selectedRightIds.includes(user.id));
            setUsers(prev => [...prev, ...newUnselected]);
            setSelectedUsers(prev => prev.filter(user => !selectedRightIds.includes(user.id)));
            setSelectedRightIds([]);
            setIsAllRightSelected(false);
        };

        const handleSearch = () => {
            const searchValue = searhNameRef.current.value.toLowerCase();
            setSearchTerm(searchValue);
        };
        
        const filteredUsers = users.filter(user => 
            user.username.toLowerCase().includes(searchTerm)
        );
    
        return (
            <>
                <div className='message_modal_background' onClick={closeModal} />
                <div className='message_modal_container'>
                    <div className='flexcenter message_title_area'>
                        <div className='message_title'>메시지 발송 대상자</div>
                        <img src={BtnClose} className="btn_1dot5rem message_btn_close" alt='닫기'
                            onClick={closeModal}
                        ></img>
                    </div>
                    {/* <select className="btn_midside" style={{width:"6.25rem",textAlign:'center'}} 
                            value={selSeason}
                            onChange={(e) => {
                                setSelSeason(e.target.value);
                            }}>
                        {seasonInfo.map(season => (
                            <option key={season.seasoncode} value={season.seasoncode}>{season.seasonname}</option>
                        ))}
                    </select> */}
                    <div className='flexcenter message_btn_group'>
                        <div className='flexcenter message_btn_sort_wrap'>
                            <img src={BtnSort} alt="정렬" className='btn_1rem' />
                            <select className='message_btn_sort_text'
                                value={selMemberType}
                                onChange={(e) => setSelMemberType(e.target.value)}
                            >
                                <option value="A">전체</option>
                                <option value="P">학부모</option>
                                <option value="C">학생</option>
                            </select>
                        </div>
                        <div className='flexcenter message_btn_sort_wrap'>
                            <img src={BtnSort} alt="정렬" className='btn_1rem' />
                            <select className='message_btn_sort_text' 
                                value={sortOption} onChange={handleSortChange}>
                                <option value="seat">좌석번호</option>
                                <option value="name">이름</option>
                            </select>
                        </div>
                        <div className='flexcenter message_search_wrap'>
                            <input type="text" placeholder="이름" ref={searhNameRef}
                                defaultValue={searhNameRef?.current && searhNameRef.current.value}
                                className='message_search_input'
                            />
                            <div className='flexcenter message_search_icon_wrap'>
                                <img src={BtnSearch} className="message_search_icon" alt='검색'
                                    onClick={handleSearch}
                                ></img>
                            </div>
                        </div>
                    </div>
                    <div className='flexcenter message_people_list_wrap'>
                        <div className='message_people_list'>
                            <div className='message_people_list_header_wrap'>
                                <img src={UnRead} className="btn_1rem" alt='발송대상자' />
                                <div className='message_people_list_header_text'>발송 대상자 목록</div>
                            </div>
                            <div className='message_people_list_control_wrap'>
                                <img alt='전체선택' src={isAllLeftSelected ? CheckboxSel : Checkbox} className='btn_0dot75rem' onClick={handleBulkLeftSelect}/>
                                <div className='message_people_list_control_field control_field_name'>이름</div>
                                <div className='message_people_list_control_field'>좌석번호</div>
                            </div>
                            <div ref={leftScrollRef} className='message_scroll_y'>
                                {filteredUsers.map(user => (
                                    <div key={user.id}
                                        onClick={() => handleSelectLeft(user)}
                                        className='message_people_list_body_wrap'
                                        style={{backgroundColor: selectedLeftIds.includes(user.id) ? '#EAF9FF' : 'white'}}
                                    >
                                        <img alt='체크박스' src={selectedLeftIds.includes(user.id) ? CheckboxSel : Checkbox} className='btn_0dot75rem' />
                                        <div className='flexcenter message_people_list_body_name'>
                                            {user.username}
                                            {user.parenttype ?
                                                <div className='message_parenttype'>{user.parenttype}</div>
                                            :   null
                                            }
                                        </div>
                                        <div className='flexcenter message_people_list_body_seatno'>{user.seatno}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div style={{display:'flex',flexDirection:'column'}}>
                            <img alt='우측이동' style={{cursor:'pointer'}} src={ArrowRight} onClick={moveToRight} />
                            <div style={{height:'0.75rem'}} />
                            <img alt='좌측이동' style={{cursor:'pointer'}} src={ArrowLeft} onClick={moveToLeft} />
                        </div>
                        <div className='message_people_list'>
                            <div className='message_people_list_header_wrap'>
                                <img src={UnRead} className="btn_1rem" alt='선택된목록' />
                                <div className='message_people_list_header_text'>선택된 목록</div>
                            </div>
                            <div className='message_people_list_control_wrap'>
                                <img alt='전체선택' src={isAllRightSelected ? CheckboxSel : Checkbox} className='btn_0dot75rem' onClick={handleBulkRightSelect}/>
                                <div className='message_people_list_control_field control_field_name'>이름</div>
                                <div className='message_people_list_control_field'>좌석번호</div>
                            </div>
                            <div ref={rightScrollRef} className='message_scroll_y'>
                                {selectedUsers.map(user => (
                                    <div key={"S"+user.id}
                                        onClick={() => handleSelectRight(user)}
                                        className='message_people_list_body_wrap'
                                        style={{backgroundColor: selectedRightIds.includes(user.id) ? '#EAF9FF' : 'white'}}
                                    >
                                        <img alt='체크박스' src={selectedRightIds.includes(user.id) ? CheckboxSel : Checkbox} className='btn_0dot75rem' />
                                        <div className='flexcenter message_people_list_body_name'>
                                            {user.username}
                                            {user.parenttype ?
                                                <div className='message_parenttype'>{user.parenttype}</div>
                                            :   null
                                            }
                                        </div>
                                        <div className='flexcenter message_people_list_body_seatno'>{user.seatno}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return (
        <div className="content_area">
            <div className='menutitlebox'>신규메시지 작성</div>
            <div className='message_write_menutitle'>제목</div>
            <input className='message_write_input'
                placeholder="제목을 입력하세요" 
                value={title}
                onChange={handleTitleChange}
            />
            <div className='message_write_menutitle'>내용</div>
            <textarea className='message_write_textarea'
                placeholder="내용을 입력하세요"
                value={content}
                onChange={handleContentChange}
            />
            <div style={{display:'flex'}}>
                <div className='message_write_menutitle'>발송대상</div>
                <button className='message_write_button' onClick={openModal}>선택</button>
            </div>
            <div className='message_write_receiver_list'>
                {selectedUsers.map(user => (
                    <div key={user.id} className='flexcenter message_write_receiver_body'>
                        {user.username}
                        <div style={{marginRight:'0.25rem'}} />
                        <div className='flexcenter message_people_list_body_seatno'>{user.seatno}</div>
                        {user.parenttype ?
                            <div className='message_parenttype'>{user.parenttype}</div>
                        :   null
                        }
                        <div style={{marginRight:'0.25rem'}} />
                        <img src={BtnClose} className="btn_0dot875rem MiniClose" alt='닫기'
                            onClick={() => delselecteduser(user.id)}
                        ></img>
                    </div>
                ))}
            </div>
            {isModalOpen && (
                <ShowModal closeModal={closeModal} />
            )}
            <div className='mainbuttonarea'>
                <button className='mainbutton' style={{background:'#F4F4F4',color:'#373737',marginRight:'0.75rem'}} 
                    onClick={makeMessageData}>발송</button>
                <button className='mainbutton' onClick={()=>navigate(-1)}>뒤로</button>
            </div>
        </div>
    );
}

export default MessageWrite;