import React, { useEffect, useState, useCallback } from 'react';
import { db } from '../firebase_config.js';
import { collection, getDocs, doc, updateDoc, query, where, deleteDoc, deleteField } from "firebase/firestore";
import 'react-calendar/dist/Calendar.css';
import AlmMobile from '../images/buttons/alarm_mobile.png';
import Swal from "sweetalert2";

function AdminPage() {
    // const [scheduleData, setScheduleData] = useState([]);

    // const callDb = useCallback(async () => {
    //     const schq = query(collection(db, "schedules"),
    //                     where("groupid", "==", "sd3"),
    //                     where('dayend', '>=', '2000-01-01'),
    //                     where('dayend', '<=', '2999-12-31'));
    //     const schsnapshot = await getDocs(schq); //users 테이블의 userId 중에 입력한 이메일주소가 있는지;
    //     const schData = schsnapshot.docs.map(doc => ({
    //         ...doc.data(),
    //         id: doc.id
    //     }));

    //     setScheduleData(schData);
    // }, [])

    // useEffect(() => {
    //     callDb();
    // }, [callDb])

    // const fieldDel = async () => {
    //     try {
    //         const collectionRef = collection(db, 'schedules');
    //         const q = query(collectionRef, 
    //             where("groupid", "==", "sd3"),
    //             where("dayend", ">=", "2000-01-01"),
    //             where("dayend", "<=", "2999-12-31")
    //         );
            
    //         const snapshot = await getDocs(q);
            
    //         snapshot.forEach((docSnapshot) => {
    //             deleteDoc(docSnapshot.ref);
    //         });
            
    
    //         Swal.fire({
    //             icon: "success",
    //             title: "문서 삭제 완료",
    //             text: `${snapshot.size}개의 문서가 삭제되었습니다.`,
    //             confirmButtonText: "확인",
    //         });
    //     } catch(error) {
    //         console.log("에러메세지:", error.message);
    //         Swal.fire({
    //             icon: "error",
    //             title: "삭제 실패",
    //             text: error.message,
    //             confirmButtonText: "확인",
    //         });
    //     }
    // }

    const fieldDel = async () => {
        try {
            const collectionRef = collection(db, 'users');
            
            const snapshot = await getDocs(collectionRef);
            snapshot.forEach((docSnapshot) => {
                updateDoc(docSnapshot.ref, {
                    alarmyn: deleteField(),
                });
            });
    
            Swal.fire({
                icon: "success",
                title: "문서 삭제 완료",
                text: "삭제되었습니다.",
                confirmButtonText: "확인",
            });
        } catch(error) {
            console.log("에러메세지:", error.message);
            Swal.fire({
                icon: "error",
                title: "삭제 실패",
                text: error.message,
                confirmButtonText: "확인",
            });
        }
    }

    const fieldAdd = async () => {
        try {
            const collectionRef = collection(db, 'users');
            // const q = query(collectionRef, 
            //     where("groupid", "==", "sd3"),
            //     where("dayend", ">=", "2000-01-01"),
            //     where("dayend", "<=", "2999-12-31")
            // );
            
            const snapshot = await getDocs(collectionRef);
            
            snapshot.forEach((docSnapshot) => {
                updateDoc(docSnapshot.ref, {
                    alarmyn: true
                });
            });
            
    
            Swal.fire({
                icon: "success",
                title: "필드 추가 완료",
                // text: `${snapshot.size}개의 문서가 삭제되었습니다.`,
                confirmButtonText: "확인",
            });
        } catch(error) {
            console.log("에러메세지:", error.message);
            Swal.fire({
                icon: "error",
                title: "필드 추가 실패",
                text: error.message,
                confirmButtonText: "확인",
            });
        }
    }

    return (
        <>
            <div className="content_wrap">
                <div style={{width:'100%',marginTop:"20px",marginBottom:"100px"}}>
                    <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}>관리자페이지</div>
                </div>
                <div style={{width:"100%",height:"40px",display:'flex',color:"#8D8D8D",fontWeight:400,fontSize:"15px",borderBottom:"#EEEEEE 1px solid",lineHeight:"40px"}}>
                    <div style={{width:"200px"}} onClick={()=>{
                        fieldAdd();
                        // fieldDel();
                    }}>업데이트</div>
                </div>
                <footer>
                    <div className="copyright" style={{marginTop:"50px",marginBottom:"50px"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 태블릿 영역 */}
            <div className="content_wrap_tablet">
                <div style={{width:"95%",marginTop:"20px",marginBottom:"100px"}}>
                    <div className="title_absent" style={{color:"#C1C1C1",cursor:"pointer"}}>관리자페이지</div>
                </div>
                <div style={{width:"95%",height:"48px",display:'flex',color:"#A6A7AA",fontWeight:400,fontSize:"16px",borderBottom:"#EEEEEE 1px solid",lineHeight:"48px"}}>
                    <div style={{width:"10%"}} onClick={()=>{
                        // fieldAdd();
                        fieldDel();
                    }}>업데이트</div>
                </div>
                <footer>
                    <div className="copyright" style={{marginTop:"50px",marginBottom:"50px"}}>
                        <div>Copyright 2023. 시대정신학원 All rights reserved.</div>
                    </div>
                </footer>
            </div>
            {/* 모바일 영역 */}
            <div className="content_wrap_mobile">
                <div className="no_provide">
                    <img src={AlmMobile} style={{width:"64px",height:"64px",marginBottom:"30px"}} alt='알림' />
                    <div>스마트폰에서</div>
                    <div>지원하지 않는 화면입니다.</div>
                </div>
            </div>
        </>
    );
}

export default AdminPage;